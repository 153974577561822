export const BLACK = '#000000';
export const WHITE = '#ffffff';
export const RED = '#F24242';
export const GRAY = '#5C5C5C';
export const GRAY_LIGHT = '#D2D7DE';
export const GRAY_LIGHTEST = '#F3F4F9';
export const PURPLE = '#6456F2';
export const BRIGHT_PURPLE = '#9442FF';
export const DYNAMIC_BLUE = '#00B6FF';
export const PURPLE_INACTIVE = '#8A8CB3';
export const PURPLE_A = '#8002EF';
export const PURPLE_B = '#7229E5';
export const PURPLE_C = '#6405FA';
export const PURPLE_D = '#5E48FF';
export const PURPLE_E = '#630EAD';
export const PURPLE_F = '#4E0191';
export const PURPLE_G = '#3F039C';
export const BLUE_A = '#381CFF';
export const BLUE_B = '#0422FA';
export const BLUE_C = '#2116E8';
export const BLUE_D = '#03159C';
export const BLUE_E = '#00B6FF';
export const BLUE_F = '#39E3FF';
export const BLUE_G = '#0073A1';
export const GREEN_A = '#04EF8A';
export const GREEN_B = '#0DBC71';
export const GREEN_C = '#029154';
export const GREEN_D = '#0DFAE4';
export const GREEN_E = '#34E8D7';
export const GREEN_F = '#089C8F';
