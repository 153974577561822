import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ModalContentEditUserProfile.module.css';
import { ButtonAlpha, TextInput } from '../../components';
import PhoneInput from 'react-phone-input-2';
function ModalContentEditUserProfile({ handleCloseUpdateProfile }) {
  const dispatch = useDispatch();
  const { email, name, phone } = useSelector((state) => state.login);
  const [newName, setNewName] = useState(name);
  const [newEmail, setNewEmail] = useState(email);
  const [newPhone, setNewPhone] = useState(phone);
  const [password, setNewPassword] = useState('');

  const avoidPropagation = (event) => {
    event.stopPropagation();
  };

  const updateProfile = async (event) => {
    event.stopPropagation();
    const userId = await localStorage.getItem('userId');
    // dispatch({
    //   type: loginContants.ON_UPDATE_USER,
    //   password: password,
    //   name: newName,
    //   email: newEmail,
    //   phone: newPhone,
    //   userId,
    // });
    handleCloseUpdateProfile();
  };
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Datos de cuenta</span>
      </div>
      <div className={styles.list} onClick={avoidPropagation}>
        <div className={styles.row}>
          <TextInput
            value={newName}
            viewLabel={true}
            setValue={setNewName}
            placeholder={'Nombre'}
          />
        </div>
        <div className={styles.row}>
          <TextInput
            value={newEmail}
            viewLabel={true}
            setValue={setNewEmail}
            placeholder={'Correo'}
          />
        </div>
        <div className={styles.row}>
          <div style={{ marginBottom: '5px' }}>
            <label>Teléfono</label>
          </div>
          <PhoneInput
            inputStyle={{
              height: '48px',
              borderRadius: '15px',
              borderColor: '#8A8CB2',
              width: '100%',
            }}
            buttonStyle={{
              height: '48px',
              borderTopLeftRadius: '15px',
              borderBottomLeftRadius: '15px',
              borderColor: '#8A8CB2',
            }}
            country={'mx'}
            value={phone}
            setValue={setNewPhone}
            onChange={setNewPhone}
          />
        </div>
        <div className={styles.row}>
          <TextInput
            value={password}
            viewLabel={true}
            setValue={setNewPassword}
            placeholder={'Contraseña'}
          />
        </div>
      </div>
      <div className={styles.row}>
        <ButtonAlpha
          label={'Guardar cambios'}
          onClick={updateProfile}
          style={{
            alignSelf: 'center',
            marginTop: '10px',
          }}
        />
      </div>
    </div>
  );
}

ModalContentEditUserProfile.propTypes = {
  handleCloseUpdateProfile: PropTypes.func,
};

export default ModalContentEditUserProfile;
