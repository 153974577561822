/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import { css } from '@emotion/react';
import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  GRAY,
  GRAY_LIGHT,
  PURPLE_A,
  PURPLE_INACTIVE,
  RED,
  WHITE,
} from '../../../styles/colors';
import Text from '../../Text';
import { defaultFont } from '../../../styles/fonts';
import { forwardInputEventHandlers } from '../../../utils/form.utils';
import Button from '../../buttons/Button';
import Icon from '../../Icon';
import { Eye, EyeOff } from '../../../assets/Icons';

const PasswordInput = forwardRef((props, ref) => {
  const { error, className, inputClassName, ...restProps } = props;
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const styles = css`
    z-index: 0;
    position: relative;
    margin-bottom: 25px;
    box-sizing: border-box;
    width: 100%;

    & > * {
      box-sizing: border-box;
    }

    .error-text {
      z-index: 1;
      bottom: -19px;
      position: absolute;
    }

    .inputWrapper {
      z-index: 2;
      display: flex;
      align-items: center;
      height: 60px;
      width: 100%;
      background-color: ${WHITE};
      padding-right: 26px;
      border-radius: 16px;
      border: 1px solid
        ${error ? RED : isFocused ? PURPLE_A : PURPLE_INACTIVE};

      input:-webkit-autofill {
        -webkit-text-fill-color: ${GRAY};
        background-color: ${WHITE} !important;
        box-shadow: inset 0 0 0 1px rgb(255 255 255 / 0%),
          inset 0 0 0 1000px ${WHITE};
      }

      input {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        padding: 0 19px;
        color: ${GRAY};
        font-family: ${defaultFont};
        font-size: 16px;
        outline: none;
        border: none;
        border-radius: inherit;

        &::placeholder {
          color: ${GRAY_LIGHT};
        }
      }

      .icon {
        &:hover {
          opacity: 0.5;
        }
        transition: opacity 300ms;
      }
    }
  `;

  const handleIsPasswordHidden = () => {
    setIsPasswordHidden((state) => !state);
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    restProps.onBlur?.(e);
  };

  const handleFocus = (e) => {
    setIsFocused(true);
    restProps.onFocus?.(e);
  };

  return (
    <div ref={ref} css={styles} className={className}>
      {!!error && (
        <Text
          fontSize={14}
          lineHeight={17}
          fontWeight="500"
          color={RED}
          className="error-text"
        >
          {error}
        </Text>
      )}
      <div className="inputWrapper">
        <input
          type={isPasswordHidden ? 'password' : 'text'}
          className={inputClassName}
          {...restProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <Button tabIndex="1" onClick={handleIsPasswordHidden}>
          <Icon
            className="icon"
            size={20}
            color={
              error ? RED : isFocused ? PURPLE_A: PURPLE_INACTIVE
            }
            icon={isPasswordHidden ? Eye : EyeOff}
          />
        </Button>
      </div>
    </div>
  );
});

export const FormPasswordInput = (props) => {
  const { name, error, ...restProps } = props;

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <PasswordInput
          name={name}
          value={meta.value}
          error={meta.touched && (meta.error || error)}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

FormPasswordInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

PasswordInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
