/* eslint-disable react/prop-types */
import styles from './CardTypesCampaign.module.css';

function CardTypesCampaign({ size = 'small', children, onClick }) {
  if (size === 'small') {
    return (
      <div className={styles.containerSmall} onClick={onClick}>
        <div className={styles.Card}>{children}</div>
      </div>
    );
  }

  return (
    <div className={styles.containerLarge} onClick={onClick}>
      <div className={styles.Card}>{children}</div>
    </div>
  );
}
export default CardTypesCampaign;
