/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import PropTypes from 'prop-types';
import React from 'react';

import { mergeRefs } from '../../../utils/react';

const Button = React.forwardRef((props, ref) => {
  const {
    onClick,
    onKeyUp,
    className,
    onKeyDown,
    disabled = false,
    layoutOnly = false,
    ...rest
  } = props;

  const innerRef = React.useRef(null);

  const styles = css`
    cursor: ${disabled ? 'default' : 'pointer'};
  `;

  function handleClick(event) {
    if (!disabled) {
      onClick?.(event);
    }
  }

  function handleKeyDown(event) {
    const firedByItself = event.target === innerRef.current;

    if (event.key === ' ') {
      if (firedByItself) {
        event.preventDefault();
      }
    } else if (event.key === 'Enter') {
      if (firedByItself) {
        event.preventDefault();
        innerRef.current.click();
      }
    }

    onKeyDown?.(event);
  }

  function handleKeyUp(event) {
    const firedByItself = event.target === innerRef.current;

    if (event.key === ' ') {
      if (firedByItself) {
        event.preventDefault();
        innerRef.current.click();
      }
    }

    onKeyUp?.(event);
  }

  let containerProps = { ...rest };

  if (!layoutOnly) {
    containerProps = {
      tabIndex: 0,
      ...rest,
      role: 'button',
      onKeyUp: handleKeyUp,
      onClick: handleClick,
      onKeyDown: handleKeyDown,
    };
  }

  return (
    <div
      css={styles}
      className={className}
      ref={mergeRefs(ref, innerRef)}
      {...containerProps}
    >
      {props.children}
    </div>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  layoutOnly: PropTypes.bool,
  children: PropTypes.node,
};

export default Button;
