import React from 'react';
import PropTypes from 'prop-types';
import styles from './TextInput.module.css';

function TextInput({
  value,
  setValue,
  placeholder,
  viewLabel = false,
  maxLength = 500,
  containerStyle = {},
}) {
  function handleOnChange(event) {
    setValue(event.target.value);
  }

  return (
    <div style={containerStyle}>
      {viewLabel ? <label>{placeholder}</label> : ''}
      <div>
        <input
          value={value}
          type="text"
          maxLength={maxLength}
          onChange={handleOnChange}
          className={styles.container}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

TextInput.propTypes = {
  containerStyle: PropTypes.object,
  value: PropTypes.string,
  setValue: PropTypes.func,
  placeholder: PropTypes.string,
  viewLabel: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default TextInput;
