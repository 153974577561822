import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PasswordInput.module.css';
import { IconEyeOff, IconEye } from '../../assets';

function PasswordInput({ value, setValue }) {
  const [isPasswordShowed, setIsPasswordShowed] = useState(false);
  function showPassword() {
    setIsPasswordShowed((current) => !current);
  }
  function handleOnChange(event) {
    setValue(event.target.value);
  }
  return (
    <div className={styles.container}>
      <input
        value={value}
        type={!isPasswordShowed ? 'password' : 'text'}
        onChange={handleOnChange}
        placeholder={'Contraseña'}
        className={styles.input}
      />
      <div onClick={showPassword} className={styles.iconContainer}>
        <img
          draggable={false}
          src={!isPasswordShowed ? IconEyeOff : IconEye}
        />
      </div>
    </div>
  );
}

PasswordInput.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
};

export default PasswordInput;
