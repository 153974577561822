import {
    get,
    post,
    putAuth,
    getAuth,
    postAuth,
} from './verbs';

const updateUser = (userId, payload) => {
    return putAuth(`users/${userId}`, payload);
};
  
const getInterests = () => {
    return getAuth('interests');
};
  
const signUpUserByEmail = (payload) => {
    return post('auth/signup', payload);
};
  
const signUpUserByPhone = (payload) => {
    return post('auth/signup', payload);
};

const verifyCode = (payload) => {
    return post('auth/confirmVerificationCode', payload);
};

const resendVerifyCode = (payload) => {
    return post('auth/getVerificationCode', payload);
};

const login = (payload) => {
    return post('auth/login', payload);
};

const loginByGoogle = (payload) => {
    return post('auth/google-autentication', payload);
};

const sendForgotPasswordVerificationCode = ({ key_user }) => {
    return post('auth/reset-password-send', {
    key_user,
    device: 'WEB',
});
};

const forgotPasswordValidateCode = (payload) => {
    return post('auth/reset-password-validate', payload);
};

const resetPassword = (payload) => {
    return post('auth/reset-password', payload);
};




const getCategories = (payload) => {
    return get('categories', payload);
};

const getMedals = (userId) => {
    return get(`medals/${userId}`, {});
};

const getNotifications = (userId) => {
    return get(`notifications/${userId}`, {});
};


const getProfile = () => {
    return getAuth('profile', {});
};

const getAllMedals = () => {
    return get('medals', {});
};

const getPlans = async () => {
    return getAuth('plans', {});
};

const suscriptionPayment = (payload) => {
    return postAuth('payments/subscriptions', payload);
};

const getCreditCardMethodId = (payload) => {
    return postAuth('payments/credit-cards', payload);
};

export {
    login,
    getPlans,
    getMedals,
    verifyCode,
    updateUser,
    getProfile,
    getInterests,
    getAllMedals,
    getCategories,
    resetPassword,
    loginByGoogle,
    getNotifications,
    resendVerifyCode,
    signUpUserByEmail,
    signUpUserByPhone,
    suscriptionPayment,
    getCreditCardMethodId,
    forgotPasswordValidateCode,
    sendForgotPasswordVerificationCode,
};
