import styles from './AlertMessage.module.css';
import PropTypes from 'prop-types';

export const showAlert = (value, setValue) => {
  setValue(true);
  setTimeout(() => {
    setValue(false);
  }, 2000);
};

function AlertMessage({
  label = 'Generic Message ',
  visible = false,
}) {
  if (visible) {
    return (
      <div className={styles.container}>
        <h6 className={styles.label}>{label}</h6>
      </div>
    );
  }
  return null;
}

AlertMessage.propTypes = {
  label: PropTypes.string,
  visible: PropTypes.bool,
};

export default AlertMessage;
