import axios from 'axios';

//por definir url de produccion
// eslint-disable-next-line no-undef
const API =
  process.env.REACT_APP_ENV_URL || 'https://example.com/';

console.log("API",API)

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    return Promise.reject(
      new Error(
        error.response.data.message || 'Hubo un error inesperado',
      ),
    );
  },
);

axios.interceptors.request.use(
  (request) => {
    return {
      ...request,
      headers: {
        ...request.headers,
        'Accept-language': 'es',
      },
    };
  },
  (error) => {
    return Promise.reject(
      new Error(
        error.response.data.message || 'Hubo un error inesperado',
      ),
    );
  },
);



const get = async (endpoint) => {
    return await axios.get(
      `${API}${endpoint}`,
      {},
    );
  };
  
  const post = async (endpoint, payload) => {
    return await axios.post(`${API}${endpoint}`, payload );
  };
  
  const put = async (endpoint, payload) => {
    return await axios.put(`${API}${endpoint}`, payload);
  };
  
  const putAuth = async (endpoint, payload) => {
    const token = await localStorage.getItem('token');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.put(`${API}${endpoint}`, payload, config);
  };
  
  const getAuth = async (endpoint) => {
    const token = await localStorage.getItem('token');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.get(`${API}${endpoint}`, config);
  };
  const getAuthFile = async (endpoint) => {
    const token = await localStorage.getItem('token');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: 'blob',
      },
    };
    return await axios.get(`${API}${endpoint}`, config);
  };
  
  const postAuth = async (endpoint, payload) => {
    const token = await localStorage.getItem('token');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.post(`${API}${endpoint}`, payload, config);
  };
  
  const deleteAuth = async (endpoint, payload) => {
    const token = await localStorage.getItem('token');
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        source: payload,
      },
    };
    return await axios.delete(`${API}${endpoint}`, config);
  };

  export {
    get,
    post,
    put,
    putAuth,
    getAuth,
    getAuthFile,
    postAuth,
    deleteAuth
  }