import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../contants';
import Text from '../../components/Text';
import styles from './HomeScreen.module.css';
import { Header, SideMenu } from '../../components';

function HomeScreen() {
  const navigate = useNavigate();
  const isProfileCompleted = useSelector(
    (state) => state.login.isProfileCompleted,
  );

  useEffect(() => {
    if (isProfileCompleted) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [isProfileCompleted]);

  return (
    <div className={styles.container}>
      <SideMenu />
      <div className={styles.content}>
        <Header />
        <div className={styles.main}>
          <div className={styles.placeholderData}>
            <Text align="center" fontSize={14}>
              Aquí podrás ver tu actividad reciente una vez que hayas
              completado tu perfil.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
