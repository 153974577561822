import styles from './SecurityBar.module.css';
import PropTypes from 'prop-types';

function SecurityBar({ value, maxValue }) {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.title}>Seguridad de contraseña</div>
      </div>
      <div className={styles.barContainer}>
        <div
          className={styles.activeBar}
          style={{ width: `${(value * 100) / maxValue}%` }}
        />
        <div className={styles.inactiveBar} />
      </div>
    </div>
  );
}

SecurityBar.propTypes = {
  value: PropTypes.number,
  maxValue: PropTypes.number,
};

export default SecurityBar;
