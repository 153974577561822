/* eslint-disable react/prop-types */

import styles from './Card.module.css';

function Card({ size = 'small', children }) {
  if (size === 'small') {
    return <div className={styles.containerSmall}>{children}</div>;
  }

  if (size === 'large') {
    return <div className={styles.containerLarge}>{children}</div>;
  }

  return <div className={styles.containerNormal}>{children}</div>;
}

export default Card;
