import 'react-datepicker/dist/react-datepicker.css';
import Text from '../../components/Text';
import styles from './DashBoardScreen.module.css';
import { SideMenu, Header } from '../../components';

function DashBoardScreen() {

  return (
    <div className={styles.container}>
      <SideMenu />
      <div className={styles.contentContainer}>
        <Header />
        <div className={styles.chartsContainer}>
          <div className={styles.topCards}>
            <div className={styles.topCards_card}>
              <Text fontSize={18}>Impresiones</Text>
              <div className={styles.card_data}>
                <div className={styles.data_item}>
                  <Text fontSize={32} fontWeight="bold">
                  </Text>
                  <Text fontSize={12}>
                    Total de vistas de contenidos
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoardScreen;
