import styles from './NotFound.module.css';
import { LoginBackground } from '../../assets';

function NotFound() {
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${LoginBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <h1 className={styles.title}>404 pagina no encontrada...</h1>
    </div>
  );
}

export default NotFound;
