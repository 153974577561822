import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ROUTES } from './contants';
import {
  HomeScreen,
  LoginScreen,
  ProfileSettingsScreen,
  RegistrationScreen,
  RegistrationVerificationCodeScreen,
  RegistrationCustomerSuccess,
  RegistrationCustomerProfile,
  RegistrationPhoneVerificationCodeScreen,
  NotFound,
  PaymentScreen,
  DashBoardScreen,
  TermsAndConditionsScreen,
  PolicyScreen,
  SubcriptionCustomerSuccess,
  SettingsScreen,
  RegistrationTeacherScreen,
  RegistrationTeacherVerificationCodeScreen,
  RegistrationTeacherSuccess,
  RegistrationTeacherProfile,
  RegistrationTeacherPhoneVerificationCodeScreen,
} from './screens';
import ForgotPasswordScreen from './screens/forgotPassword';

const RootNavigator = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path={ROUTES.POLICY} element={<PolicyScreen />} />
      <Route
        path={ROUTES.TERMS_AND_CONDITIONS}
        element={<TermsAndConditionsScreen />}
      />
      <Route path={ROUTES.HOME} element={<HomeScreen />} />
      <Route path={ROUTES.PAYMENT} element={<PaymentScreen />} />
      <Route path={ROUTES.DASHBOARD} element={<DashBoardScreen />} />
      <Route
        path={ROUTES.SUBSCRIPTION_SUCCESS}
        element={<SubcriptionCustomerSuccess />}
      />
      <Route
        exact
        path={ROUTES.PROFILE_SETTINGS}
        element={<ProfileSettingsScreen />}
      />
      <Route
        exact
        path={ROUTES.SETTINGS}
        element={<SettingsScreen />}
      />

      <Route
        exact
        path={ROUTES.REGISTRATION}
        element={<RegistrationScreen />}
      />
      <Route
        exact
        path={ROUTES.REGISTRATION_VERIFICATION_CODE}
        element={<RegistrationVerificationCodeScreen />}
      />
      <Route
        exact
        path={ROUTES.REGISTRATION_PROFILE}
        element={<RegistrationCustomerProfile />}
      />
      <Route
        exact
        path={ROUTES.REGISTRATION_PROFILE_SUCESS}
        element={<RegistrationCustomerSuccess />}
      />
      <Route
        exact
        path={ROUTES.REGISTRATION_VERIFY_CODE_PHONE}
        element={<RegistrationPhoneVerificationCodeScreen />}
      />
      <Route exact path={ROUTES.LOGIN} element={<LoginScreen />} />
      <Route
        exact
        path={ROUTES.FORGOT_PASSWORD}
        element={<ForgotPasswordScreen />}
      />
      <Route
        exact
        path={ROUTES.TEACHER_REGISTRATION}
        element={<RegistrationTeacherScreen />}
      />
      <Route
        exact
        path={ROUTES.TEACHER_REGISTRATION_VERIFICATION_CODE}
        element={<RegistrationTeacherVerificationCodeScreen />}
      />
      <Route
        exact
        path={ROUTES.TEACHER_REGISTRATION_PROFILE_SUCESS}
        element={<RegistrationTeacherSuccess />}
      />
      <Route
        exact
        path={ROUTES.TEACHER_REGISTRATION_PROFILE}
        element={<RegistrationTeacherProfile />}
      />
      <Route
        exact
        path={ROUTES.TEACHER_REGISTRATION_VERIFY_CODE_PHONE}
        element={<RegistrationTeacherPhoneVerificationCodeScreen />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default RootNavigator;
