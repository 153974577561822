import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ModalContentEditUserProfile.module.css';
import { ButtonAlpha, TextInput } from '../../components';
function ModalContentEditBussinesProfileInProfile({
  handleCloseUpdateProfile,
}) {
  const dispatch = useDispatch();
  const { customers } = useSelector((state) => state.login);

  const [newName, setNewName] = useState(customers[0]?.name);
  const [newAddress, setNewAddress] = useState(customers[0]?.address);
  const [isDisabledSave, setIsDisabledSave] = useState(true);

  const avoidPropagation = (event) => {
    event.stopPropagation();
  };

  const updateProfile = async (event) => {
    event.stopPropagation();
    // dispatch({
    //   type: loginContants.ON_UPDATE_CUSTOMER,
    //   name: newName,
    //   address: newAddress,
    //   customerId: customers[0]._id,
    // });
    handleCloseUpdateProfile();
  };

  useEffect(() => {
    const isDisabled = newName === '' || newAddress === '';
    if (isDisabled) {
      setIsDisabledSave(true);
    } else {
      setIsDisabledSave(false);
    }
  }, [newName, newAddress]);
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Información del negocio</span>
      </div>
      <div className={styles.list} onClick={avoidPropagation}>
        <div className={styles.row}>
          <TextInput
            value={newName}
            viewLabel={true}
            setValue={setNewName}
            placeholder={'Nombre'}
          />
        </div>
        <div className={styles.row}>
          <TextInput
            value={newAddress}
            viewLabel={true}
            setValue={setNewAddress}
            placeholder={'Direccion'}
          />
        </div>
      </div>
      <div className={styles.row}>
        <ButtonAlpha
          label={'Guardar cambios'}
          disabled={isDisabledSave}
          onClick={updateProfile}
          style={{
            alignSelf: 'center',
            marginTop: '10px',
          }}
        />
      </div>
    </div>
  );
}

ModalContentEditBussinesProfileInProfile.propTypes = {
  handleCloseUpdateProfile: PropTypes.func,
};

export default ModalContentEditBussinesProfileInProfile;
