/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import 'react-phone-input-2/lib/style.css';
import { forwardRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import Text from '../../Text';
import {
  RED,
  GRAY,
  GRAY_LIGHT,
  PURPLE,
  PURPLE_INACTIVE,
  WHITE,
} from '../../../styles/colors';
import { defaultFont } from '../../../styles/fonts';
import PhoneInputComponent from 'react-phone-input-2';

const PhoneInput = forwardRef((props, ref) => {
  const { error, className, inputClassName, ...restProps } = props;
  const [isFocused, setIsFocused] = useState(false);

  const styles = css`
    box-sizing: border-box;
    z-index: 0;
    position: relative;
    width: 100%;
    margin-bottom: 25px;

    & > * {
      box-sizing: border-box;
    }

    .error-text {
      z-index: 1;
      bottom: -19px;
      position: absolute;
    }

    .inputContainer {
      z-index: 2;
      display: flex;
      flex-direction: row-reverse;
      justify-content: left;
      height: 60px;
      width: 100%;
      padding-right: 19px;
      border: 1px solid
        ${error ? RED : isFocused ? PURPLE : PURPLE_INACTIVE};
      color: ${GRAY};
      font-family: ${defaultFont};
      font-size: 16px;
      border-radius: 16px;

      .btn {
        position: static;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        border: none;
        border-radius: unset;
        border-right: 1px solid
          ${error ? RED : isFocused ? PURPLE : PURPLE_INACTIVE};
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;

        .selected-flag {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0;
          background-color: ${WHITE};
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;

          &:hover {
            background: none;
          }

          .flag {
            transform: scale(1.8);
          }
        }

        .dropDown {
          transform: translateY(-60px);
        }
      }

      .phoneInput {
        box-sizing: border-box;
        position: static;
        height: 100%;
        flex: 1;
        padding-left: 10px;
        font-family: ${defaultFont};
        color: ${GRAY};
        font-size: 16px;
        border: none;
        border-radius: unset;

        &::placeholder {
          color: ${GRAY_LIGHT};
        }
      }
    }
  `;

  const handleBlur = () => {
    setIsFocused(false);
    restProps.onBlur?.();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <div ref={ref} css={styles} className={className}>
      {!!error && (
        <Text
          fontSize={14}
          lineHeight={17}
          fontWeight="500"
          color={RED}
          className="error-text"
        >
          {error}
        </Text>
      )}
      <PhoneInputComponent
        dropdownClass="dropDown"
        buttonClass="btn"
        country={'mx'}
        inputProps={{
          onFocus: handleFocus,
        }}
        inputClass="phoneInput"
        countryCodeEditable={false}
        containerClass={'inputContainer ' + inputClassName}
        {...restProps}
        onBlur={handleBlur}
      />
    </div>
  );
});

export const FormPhoneInput = (props) => {
  const { name, error, onChange, onBlur, ...restProps } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = (newValue) => {
    setFieldValue(name, newValue);
    onChange?.(newValue);
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
    onBlur?.();
  };

  return (
    <Field name={name}>
      {({ meta }) => (
        <PhoneInput
          {...restProps}
          name={name}
          value={meta.value}
          error={meta.touched && (meta.error || error)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      )}
    </Field>
  );
};

FormPhoneInput.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

PhoneInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
