/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import PropTypes from 'prop-types';
import * as Colors from '../../styles/colors';

const Icon = (props) => {
  const { icon, className, size = 20, color = Colors.GRAY } = props;

  const styles = css`
    flex-shrink: 0;
    fill: ${color};
    width: ${size}px;
    height: ${size}px;
    margin: 0;
    padding: 0;
  `;

  let Icon = icon;

  return <Icon css={styles} className={className} />;
};

Icon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  icon: PropTypes.object,
  color: PropTypes.oneOf([
    Colors.BLACK,
    Colors.WHITE,
    Colors.RED,
    Colors.GRAY,
    Colors.GRAY_LIGHT,
    Colors.GRAY_LIGHTEST,
    Colors.BRIGHT_PURPLE,
    Colors.DYNAMIC_BLUE,
    Colors.PURPLE_INACTIVE,
    Colors.PURPLE_A,
    Colors.PURPLE_B,
    Colors.PURPLE_C,
    Colors.PURPLE_D,
    Colors.PURPLE_E,
    Colors.PURPLE_F,
    Colors.PURPLE_G,
    Colors.BLUE_A,
    Colors.BLUE_B,
    Colors.BLUE_C,
    Colors.BLUE_D,
    Colors.BLUE_E,
    Colors.BLUE_F,
    Colors.BLUE_G,
    Colors.GREEN_A,
    Colors.GREEN_B,
    Colors.GREEN_C,
    Colors.GREEN_D,
    Colors.GREEN_E,
    Colors.GREEN_F,
  ]),
};

export default Icon;
