import styles from './Policy.module.css';

function Policy() {
  return (
    <div className={styles.container}>
      <div className={styles.rightContainer}>
        <h3 className={styles.sectionTitleText}>
          1.About this policy.
        </h3>
        <h3 className={styles.contentSectionText}>
          {' '}
          In order to ensure a correct understanding of this privacy
          policy to our users, the following are the definitions used
          throughout this document:
        </h3>
        <h3 className={styles.subSectionTitleText}>Definitions</h3>

        </div>
    </div>
  );
}

export default Policy;
