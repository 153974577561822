import styles from './ButtonAlphaWhite.module.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ButtonAlphaWhite({
  onClick,
  label,
  size = 'small',
  style,
  styleLabel,
  disabled,
  leftIcon,
  FontIcon = [],
}) {
  if (size === 'small') {
    return (
      <button
        disabled={disabled}
        className={`${styles.button} ${
          leftIcon && styles.buttonLeftIcon
        }`}
        onClick={onClick}
        style={{ ...style }}
      >
        {leftIcon && <img draggable={false} src={leftIcon} />}

        <h6 className={styles.text} style={{ ...styleLabel }}>
          {FontIcon.length > 0 && (
            <FontAwesomeIcon
              icon={FontIcon}
              className={styles.FontAwesomeIcon}
            />
          )}
          {label}
        </h6>
      </button>
    );
  }

  return (
    <button
      disabled={disabled}
      className={styles.button}
      style={{ height: '60px', ...style }}
      onClick={onClick}
    >
      {leftIcon && <img draggable={false} src={leftIcon} />}

      <h6 className={styles.text} style={{ ...styleLabel }}>
        {FontIcon.length > 0 && (
          <FontAwesomeIcon
            icon={FontIcon}
            className={styles.FontAwesomeIcon}
          />
        )}
        {label}
      </h6>
    </button>
  );
}

ButtonAlphaWhite.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  styleLabel: PropTypes.object,
  leftIcon: PropTypes.object,
  FontIcon: PropTypes.array,
};

export default ButtonAlphaWhite;
