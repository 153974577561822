import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ButtonAlphaTransparent.module.css';

function ButtonAlphaTransparent({
  rightIcon,
  onClick,
  label,
  disabled,
  style,
  styleLabel,
  size = 'small',
  icon = [],
}) {
  if (size === 'small') {
    return (
      <button
        type="button"
        style={{ ...style }}
        disabled={disabled}
        className={`${styles.button} ${
          rightIcon && styles.buttonRightIcon
        }`}
        onClick={onClick}
      >
        <h6 className={styles.text} style={{ ...styleLabel }}>
          {label}
        </h6>
        {rightIcon && <img draggable={false} src={rightIcon} />}
        {icon.length > 0 ? <FontAwesomeIcon icon={icon} /> : ''}
      </button>
    );
  }

  return (
    <button
      className={styles.button}
      style={{ height: '60px' }}
      onClick={onClick}
    >
      <h6 className={styles.text}>{label}</h6>
    </button>
  );
}

ButtonAlphaTransparent.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  styleLabel: PropTypes.object,
  rightIcon: PropTypes.object,
  icon: PropTypes.string,
};

export default ButtonAlphaTransparent;
