/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import PropTypes from 'prop-types';
import * as Colors from '../../styles/colors';
import { defaultFont } from '../../styles/fonts';

const Text = (props) => {
  const {
    children,
    className,
    color = Colors.GRAY,
    fontSize = 12,
    fontWeight = 'normal',
    align = 'left',
    lineHeight = fontSize,
    type = 'block',
  } = props;

  const styles = css`
    color: ${color};
    text-align: ${align};
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
    font-family: ${defaultFont};
    font-weight: ${fontWeight};
  `;

  let ReactElement;

  switch (type) {
    case 'block':
      ReactElement = 'div';
      break;
    case 'inline':
      ReactElement = 'span';
      break;
    default:
      throw new Error('Unhandled text type');
  }

  return (
    <ReactElement css={styles} className={className}>
      {children}
    </ReactElement>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    Colors.WHITE,
    Colors.RED,
    Colors.GRAY,
    Colors.GRAY_LIGHT,
    Colors.GRAY_LIGHTEST,
    Colors.PURPLE,
    Colors.BRIGHT_PURPLE,
    Colors.DYNAMIC_BLUE,
    Colors.PURPLE_INACTIVE,
    Colors.PURPLE_A,
    Colors.PURPLE_B,
    Colors.PURPLE_C,
    Colors.PURPLE_D,
    Colors.PURPLE_E,
    Colors.PURPLE_F,
    Colors.PURPLE_G,
    Colors.BLUE_A,
    Colors.BLUE_B,
    Colors.BLUE_C,
    Colors.BLUE_D,
    Colors.BLUE_E,
    Colors.BLUE_F,
    Colors.BLUE_G,
    Colors.GREEN_A,
    Colors.GREEN_B,
    Colors.GREEN_C,
    Colors.GREEN_D,
    Colors.GREEN_E,
    Colors.GREEN_F,
  ]),
  fontSize: PropTypes.number,
  fontWeight: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  lineHeight: PropTypes.number,
  type: PropTypes.oneOf(['block', 'inline']),
};

export default Text;
