import PropTypes from 'prop-types';
import styles from './ModalContentCompleteAccount.module.css';
import { ButtonAlpha } from '../../components';
import { IconCheckPurple } from '../../assets';

function ModalContentCompleteAccount({ onClick }) {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h6 className={styles.title}>
          {' '}
        </h6>
      </div>
      <div className={styles.list}>
        <div className={styles.row}>
          <img src={IconCheckPurple} draggable={false} />
          <h6 className={styles.rowText}>
            Al seleccionar un plan podrás:
          </h6>
        </div>
        <div className={styles.row}>
          <img src={IconCheckPurple} draggable={false} />
          <h6 className={styles.rowText}>
            Crear campañas personalizadas y automatizadas.
          </h6>
        </div>
        <div className={styles.row}>
          <img src={IconCheckPurple} draggable={false} />
        </div>
        <div className={styles.row}>
          <img src={IconCheckPurple} draggable={false} />
          <h6 className={styles.rowText}>
            Controlar tus resultados a un sólo click.{' '}
          </h6>
        </div>
      </div>
      <ButtonAlpha
        label={'Completar'}
        onClick={onClick}
        style={{
          width: '80%',
          alignSelf: 'center',
          marginTop: '10px',
        }}
      />
    </div>
  );
}

ModalContentCompleteAccount.propTypes = {
  onClick: PropTypes.func,
};

export default ModalContentCompleteAccount;
