import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RegistrationBackground } from '../../assets';
import { loginContants } from '../../redux/contants';
import styles from './RegistrationTeacherVerificationCodeScreen.module.css';
import { Card, ButtonAlphaWhite } from '../../components';
import ConfirmationCodeInput from './ConfirmationCodeInput';
import { ROUTES } from '../../contants';

function Header() {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        top: 10,
        height: 50,
        flexDirection: 'row',
        position: 'absolute',
      }}
    >
      <div
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '2%',
          alignItems: 'center',
        }}
      ></div>
    </div>
  );
}

function RegistrationTeacherVerificationCodeScreen() {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [visible, setVisible] = useState(false);
  const { verificationCodeEmail, email } = useSelector(
    (state) => state.login,
  );
  const handleResendCode = () => {
    // dispatch({
    //   type: loginContants.ON_RESEND_VERIFICATION_CODE_BY_EMAIL,
    // });
  };

  useEffect(() => {
    if (verificationCodeEmail) {
      navigate(ROUTES.REGISTRATION_PROFILE);
    }
  }, [verificationCodeEmail]);

  useEffect(() => {
    if (code.length === 6) {
      // dispatch({
      //   type: loginContants.ON_VERIFICATION_CODE_BY_EMAIL,
      //   code,
      // });
    }
  }, [code]);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${RegistrationBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Header />
      <Card>
        <div className={styles.cardInnerContainer}>
          <div className={styles.title}>Confirma tu correo</div>
          <div className={styles.subTitle}>
            Ingresa el código que te enviamos vía e-mail al correo{' '}
            <br /> <b>{email}</b>
          </div>

          <div
            style={{
              height: '100%',
              paddingBottom: '60px',
              width: '70%',
            }}
          >
            <ConfirmationCodeInput
              length={6}
              loading={loading}
              onComplete={(code) => {
                setCode(code);
                setLoading(true);
                setTimeout(() => setLoading(false), 10000);
              }}
            />
            <div className={styles.footer}>
              <span>¿No recibiste el código?</span>
            </div>
            <ButtonAlphaWhite
              label={'Reenviar código'}
              size={'normal'}
              onClick={handleResendCode}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default RegistrationTeacherVerificationCodeScreen;
