/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import styles from './HomeScreen.module.css';
import { useNavigate } from 'react-router-dom';

import { SideMenu, Toggle, Header } from '../../components';
import { ROUTES } from '../../contants';

function Feature({ title = '' }) {
  return (
    <div className={styles.feature}>
      <Toggle />
      <div className={styles.featureTitleText}>{title}</div>
    </div>
  );
}

function Devider() {
  return <div className={styles.devider} />;
}

function SettingsScreen() {
  const navigate = useNavigate();

  const goToPolicy = () => {
    navigate(ROUTES.POLICY);
  };
  const goToTermsAndConditions = () => {
    navigate(ROUTES.TERMS_AND_CONDITIONS);
  };
  return (
    <>
      <div className={styles.container}>
        <SideMenu />
        <div className={styles.contentContainer}>
          <Header />
          <div
            className={styles.subtitle}
            style={{ marginTop: '2.5rem' }}
          >
            Notificaciones
          </div>
          <div style={{ height: '1.5rem', width: '100%' }} />
          <Feature title={'Vía SMS'} />
          <Feature title={'Vía correo electrónico'} />
          <div style={{ height: '2.5rem', width: '100%' }} />
          <Devider />
          <div
            className={styles.subtitle}
            style={{ marginTop: '2.5rem' }}
          >
            Preferencias
          </div>
          <div style={{ height: '1.5rem', width: '100%' }} />
          <Feature title={'Dark Mode'} />
          <div style={{ height: '1.5rem', width: '100%' }} />
          <div className={styles.linkText}>Idioma</div>
          <div style={{ height: '1.5rem', width: '100%' }} />
          <div className={styles.linkText}>Centro de Ayuda</div>
          <div style={{ height: '1.5rem', width: '100%' }} />
          <Devider />
          <div
            className={styles.subtitle}
            style={{ marginTop: '1.5rem' }}
          >
            Legales
          </div>
          <div style={{ height: '1.5rem', width: '100%' }} />
          <div
            className={styles.linkText}
            onClick={goToTermsAndConditions}
          >
            Términos y condiciones
          </div>
          <div style={{ height: '1.5rem', width: '100%' }} />
          <div className={styles.linkText} onClick={goToPolicy}>
            Aviso de privacidad
          </div>
          <div style={{ height: '1.5rem', width: '100%' }} />
          <Devider />
          <div style={{ height: '1.5rem', width: '100%' }} />
          <div className={styles.tradeMark}>
            Copyright© <b>Kambal Technologies</b> 2022
          </div>
          <div style={{ height: '1.5rem', width: '100%' }} />
        </div>
      </div>
    </>
  );
}

export default SettingsScreen;
