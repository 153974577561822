/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import styles from './AccountData.module.css';
import PropTypes from 'prop-types';

function AccountDataComponent({ title, description }) {
  return (
    <div className={styles.accountDataContainer}>
      <div className={styles.accountDataTitle}>{title}</div>
      <div className={styles.descripctionDataContainer}>
        {description}
      </div>
    </div>
  );
}

function AccountData({
  onShowUpdateProfileUserModal,
  name,
  email,
  phone,
}) {
  return (
    <div className={styles.contentContainer}>
      <div className={styles.container}>
        <div className={styles.headerTitle}>
          <div>
            <h1 className={styles.headerTitleText}>
              Información de cuenta
            </h1>
          </div>
          <div onClick={onShowUpdateProfileUserModal}>
            <h1 className={styles.headerTitleEditText}>Editar</h1>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.row}>
            <AccountDataComponent
              title={'Nombre'}
              description={name}
            />
            <AccountDataComponent
              title={'Teléfono'}
              description={phone}
            />
          </div>
          <div className={styles.row}>
            <AccountDataComponent
              title={'Correo'}
              description={email}
            />
            <AccountDataComponent
              title={'Contraseña'}
              description={'*******'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountData;
