const loginContants = {
  ON_LOGIN: 'ON_LOGIN',
  ON_LOGIN_SUCCESS: 'ON_LOGIN_SUCCESS',
  ON_LOGIN_FAIL: 'ON_LOGIN_FAIL',
  ON_SET_PERFIL_COMPLETED: 'ON_SET_PERFIL_COMPLETED',
  ON_CLEAR_LOGIN_FAIL: 'ON_CLEAR_LOGIN_FAIL',
  ON_SIGNUP_BY_EMAIL: 'ON_SIGNUP_BY_EMAIL',
  ON_VERIFICATION_CODE_BY_EMAIL: 'ON_VERIFICATION_CODE_BY_EMAIL',
  ON_VERIFICATION_CODE_BY_EMAIL_SUCCESS:
    'ON_VERIFICATION_CODE_BY_EMAIL_SUCCESS',
  ON_VERIFICATION_CODE_BY_EMAIL_FAIL:
    'ON_VERIFICATION_CODE_BY_EMAIL_FAIL',
  ON_VERIFICATION_CODE_BY_PHONE: 'ON_VERIFICATION_CODE_BY_PHONE',
  ON_VERIFICATION_CODE_BY_PHONE_SUCCESS:
    'ON_VERIFICATION_CODE_BY_PHONE_SUCCESS',
  ON_VERIFICATION_CODE_BY_PHONE_FAIL:
    'ON_VERIFICATION_CODE_BY_PHONE_FAIL',
  ON_SIGNUP_BY_PHONE: 'ON_SIGNUP_BY_PHONE',
  ON_SIGNUP_BY_PHONE_RESET: 'ON_SIGNUP_BY_PHONE_RESET',
  ON_SIGNUP_SUCCESS_BY_EMAIL: 'ON_SIGNUP_SUCCESS_BY_EMAIL',
  ON_SIGNUP_SUCCESS_BY_PHONE: 'ON_SIGNUP_SUCCESS_BY_PHONE',
  ON_SIGNUP_FAIL_BY_EMAIL: 'ON_SIGNUP_FAIL_BY_EMAIL',
  ON_SIGNUP_FAIL_BY_PHONE: 'ON_SIGNUP_FAIL_BY_PHONE',
  ON_RESEND_VERIFICATION_CODE_BY_EMAIL:
    'ON_RESEND_VERIFICATION_CODE_BY_EMAIL',
  ON_RESEND_VERIFICATION_CODE_BY_EMAIL_SUCCESS:
    'ON_RESEND_VERIFICATION_CODE_BY_EMAIL_SUCCESS',
  ON_RESEND_VERIFICATION_CODE_BY_EMAIL_FAIL:
    'ON_RESEND_VERIFICATION_CODE_BY_EMAIL_FAIL',
  ON_RESEND_VERIFICATION_CODE_BY_PHONE:
    'ON_RESEND_VERIFICATION_CODE_BY_PHONE',
  ON_RESEND_VERIFICATION_CODE_BY_PHONE_SUCCESS:
    'ON_RESEND_VERIFICATION_CODE_BY_PHONE_SUCCESS',
  ON_RESEND_VERIFICATION_CODE_BY_PHONE_FAIL:
    'ON_RESEND_VERIFICATION_CODE_BY_PHONE_FAIL',
  ON_RESET_PASSWORD: 'ON_RESET_PASSWORD',
  ON_GET_INTERESTS: 'ON_GET_INTERESTS',
  ON_GET_INTERESTS_SUCCESS: 'ON_GET_INTERESTS_SUCCESS',
  ON_GET_INTERESTS_FAIL: 'ON_GET_INTERESTS_FAIL',

  ON_GET_PARTNERS_ROLES: 'ON_GET_PARTNERS_ROLES',
  ON_GET_PARTNERS_ROLES_FAIL: 'ON_GET_PARTNERS_ROLES_FAIL',
  ON_GET_PARTNERS_ROLES_SUCCESS: 'ON_GET_PARTNERS_ROLES_SUCCESS',

  ON_GET_PERSONALITIES: 'ON_GET_PERSONALITIES',
  ON_GET_PERSONALITIES_FAIL: 'ON_GET_PERSONALITIES_FAIL',
  ON_GET_PERSONALITIES_SUCCESS: 'ON_GET_PERSONALITIES_SUCCESS',

  ON_UPDATE_USER_SUCCESS: 'ON_UPDATE_USER_SUCCESS',
  ON_UPDATE_USER_FAIL: 'ON_UPDATE_USER_FAIL',
  ON_UPDATE_USER: 'ON_UPDATE_USER',
  ON_GET_CATEGORIES: 'ON_GET_CATEGORIES',
  ON_GET_CATEGORIES_SUCCESS: 'ON_GET_CATEGORIES_SUCCESS',
  ON_GET_CATEGORIES_FAIL: 'ON_GET_CATEGORIES_FAIL',
  HELP_CENTER_ICON_ACTIVATED: 'HELP_CENTER_ICON_ACTIVATED',
  HELP_CENTER_ICON_DEACTIVATED: 'HELP_CENTER_ICON_DEACTIVATED',
  FINISH_TUTORIAL: 'FINISH_TUTORIAL',
  ON_GET_USER_PROFILE: 'ON_GET_USER_PROFILE',
  ON_GET_USER_PROFILE_SUCCESS: 'ON_GET_USER_PROFILE_SUCCESS',
  ON_GET_USER_PROFILE_FAIL: 'ON_GET_USER_PROFILE_FAIL',
  ON_GET_MEDALS: 'ON_GET_MEDALS',
  ON_GET_MEDALS_SUCCESS: 'ON_GET_MEDALS_SUCCESS',
  ON_GET_MEDALS_FAIL: 'ON_GET_MEDALS_FAIL',
  ON_GET_ALL_MEDALS: 'ON_GET_ALL_MEDALS',
  ON_GET_ALL_MEDALS_SUCCESS: 'ON_GET_ALL_MEDALS_SUCCESS',
  ON_GET_ALL_MEDALS_FAIL: 'ON_GET_ALL_MEDALS_FAIL',
  ON_LOGIN_WITH_INSTAGRAM_SUCCESS: 'ON_LOGIN_WITH_INSTAGRAM_SUCCESS',
  ON_LOGIN_BY_GOOGLE: 'ON_LOGIN_BY_GOOGLE',
  ON_CREATE_CAMPAIGN: 'ON_CREATE_CAMPAIGN',
  ON_CREATE_CAMPAIGN_SUCCESS: 'ON_CREATE_CAMPAIGN_SUCCESS',
  ON_CREATE_CAMPAIGN_FAIL: 'ON_CREATE_CAMPAIGN_FAIL',
  ON_SAVE_CAMPAIGN: 'ON_SAVE_CAMPAIGN',
  ON_SAVE_CAMPAIGN_SUCCESS: 'ON_SAVE_CAMPAIGN_SUCCESS',
  ON_SAVE_CAMPAIGN_FAIL: 'ON_SAVE_CAMPAIGN_FAIL',
  ON_LOGIN_SIGN_UP: 'ON_LOGIN_SIGN_UP',
  ON_LOGIN_SIGN_UP_SUCCESS: 'ON_LOGIN_SIGN_UP_SUCCESS',
  ON_LOGIN_SIGN_UP_FAIL: 'ON_LOGIN_SIGN_UP_FAIL',
  ON_UPLOAD_IMAGE: 'ON_UPLOAD_IMAGE',
  ON_UPLOAD_IMAGE_SUCCESS: 'ON_UPLOAD_IMAGE_SUCCESS',
  ON_UPLOAD_IMAGE_FAIL: 'ON_UPLOAD_IMAGE_FAIL',
  ON_CLEAR_ERROR_MESSAGES: 'ON_CLEAR_ERROR_MESSAGES',
  ON_GET_PLANS: 'ON_GET_PLANS',
  ON_GET_PLANS_SUCCESS: 'ON_GET_PLANS_SUCCESS',
  ON_GET_PLANS_FAIL: 'ON_GET_PLANS_FAIL',
  ON_LOAD_CHECKOUT: 'ON_LOAD_CHECKOUT',
  ON_LOAD_CHECKOUT_SUCCESS: 'ON_LOAD_CHECKOUT_SUCCESS',
  ON_LOAD_CHECKOUT_FAIL: 'ON_LOAD_CHECKOUT_FAIL',
  ON_LOAD_CHECKOUT_FAIL_RESET: 'ON_LOAD_CHECKOUT_FAIL_RESET',
  ON_GET_CREDIT_CARD_METHOD_ID: 'ON_GET_CREDIT_CARD_METHOD_ID',
  ON_GET_CREDIT_CARD_METHOD_ID_SUCCESS:
    'ON_GET_CREDIT_CARD_METHOD_ID_SUCCESS',
  ON_GET_CREDIT_CARD_METHOD_ID_FAIL:
    'ON_GET_CREDIT_CARD_METHOD_ID_FAIL',
  ON_GET_PLAN_FROM_USER: 'ON_GET_PLAN_FROM_USER',
  ON_UPDATE_SUSCRIPTION_STATUS_SUCCESS:
    'ON_UPDATE_SUSCRIPTION_STATUS_SUCCESS',
  ON_UPDATE_SUSCRIPTION_STATUS: 'ON_UPDATE_SUSCRIPTION_STATUS',
  ON_CHANGE_PASSWORD: 'ON_CHANGE_PASSWORD',
  ON_CHANGE_PASSWORD_SUCCESS: 'ON_CHANGE_PASSWORD_SUCCESS',
  ON_CHANGE_PASSWORD_FAIL: 'ON_CHANGE_PASSWORD_FAIL',
};

export default loginContants;
