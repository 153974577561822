import * as Yup from 'yup';
import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES } from '../../contants';
import ProgressBar from './ProgressBar';
import { ButtonAlpha } from '../../components';
import { loginContants } from '../../redux/contants';
import styles from './RegistrationCustomerProfile.module.css';
import { RegistrationBackground } from '../../assets';
import { FormTextInput } from '../../components/inputs/TextInput/index';

function Header() {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        top: 10,
        height: 50,
        flexDirection: 'row',
        position: 'absolute',
      }}
    >
      <div
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '2%',
          alignItems: 'center',
        }}
      ></div>
    </div>
  );
}

const formValidationSchema = Yup.object({
  name: Yup.string().required('El nombre del negocio es requerido'),
  address: Yup.string().required(
    'La direccion del negocio es requerido',
  ),
});

const formInitialValues = {
  name: '',
  address: '',
};

function RegistrationCustomerProfile() {
  const { customerCreatedSuccess, userId, team_id } = useSelector(
    (state) => state.login,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);

  };



  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${RegistrationBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Header />
      <Formik
        validateOnMount
        onSubmit={handleFormSubmit}
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.cardInnerContainer}>
              <div className={styles.row}>
                <h3 className={styles.title}>Hola 👋</h3>
              </div>
              <label>Nombre del negocio</label>
              <FormTextInput
                name="name"
                placeholder="Nombre del negocio"
              />
              <label>Dirección del negocio</label>
              <FormTextInput
                name="address"
                placeholder="Calle, colonia, ciudad"
              />
              <ButtonAlpha
                label={'Siguiente'}
                onClick={formik.submitForm}
                disabled={!formik.isValid || formik.isSubmitting}
              />
              <div className={styles.row}>
                <div className={styles.ProgressBar}>
                  <div className={styles.label}>Paso 1 de 3 </div>
                  <ProgressBar
                    bgcolor="#5652E5"
                    completed="33"
                    width="100%"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default RegistrationCustomerProfile;
