import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-currency-format';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import {
  IconArrowBackBlack,
} from '../../assets';
import CheckoutForm from './CheckoutForm';
import styles from './PaymentScreen.module.css';

function Header() {
  return (
    <div className={styles.header}>

    </div>
  );
}

const translationPeriod = {
  Month: 'mes',
  Year: 'año',
};

function Form({ goBack, planSelected }) {
  const stripe_key = process.env.REACT_APP_STRIPE_KEY || '';
  const promise = loadStripe(stripe_key, { locale: 'es' });

  const [isFactura, setIsFactura] = useState(false);
  const { team_id } = useSelector((state) => state.login);

  return (
    <div className={styles.containerForm}>
      <div className={styles.form}>
        <div className={styles.formBack} onClick={goBack}>
          <img src={IconArrowBackBlack} draggable={false} />
          <div className={styles.titleText}>Datos del pago</div>
        </div>
        <div className={styles.formInnerContainer}>
          <Elements stripe={promise}>
            <CheckoutForm
              team_id={team_id}
              setIsFactura={setIsFactura}
              isFactura={isFactura}
              planSelected={planSelected}
            />
          </Elements>
        </div>
      </div>
      <div className={styles.checkoutContainer}>
        <div className={styles.checkoutTop}></div>
        <div className={styles.checkoutInnerContainer}>
          <div className={styles.leftCheckout} />
          <div className={styles.checkoutPrice}>
            <div className={styles.price}>
              <div className={styles.priceNumberText}>
                <NumberFormat
                  value={planSelected.price}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </div>
              <h4 className={styles.priceMonthlyText}>
                / {translationPeriod[planSelected.period]}
              </h4>
            </div>
          </div>
          <div className={styles.rightCheckout} />
        </div>
        <div className={styles.checkoutBottom}>
          <div className={styles.priceToChargeContainer}>
            <div>
              <h3 className={styles.priceToChargeTitleText}>
                Impuestos
              </h3>
            </div>
            <div>
              <h3 className={styles.moneyText}>
                <NumberFormat
                  value={planSelected.taxes}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </h3>
            </div>
          </div>
          <div className={styles.devider} />
          <div className={styles.priceToChargeContainer}>
            <div>
              <h3 className={styles.totalChargeTitleText}>
                Total a pagar
              </h3>
            </div>
            <div>
              <h3 className={styles.moneyText}>
                <NumberFormat
                  value={planSelected.price + planSelected.taxes}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

function PaymentScreen() {
  const [isPlanSelected, setIsPlanSelected] = useState(false);
  const [planSelected, setPlanSelected] = useState({});

  const selectPlan = (plan) => {
    setPlanSelected(plan);
    setIsPlanSelected(false);
  };

  const goToPlanSelection = () => {
    setIsPlanSelected(true);
  };

  return (
    <div className={styles.container}>
          <Header selectPlan={selectPlan} />
          <Form
            goBack={goToPlanSelection}
            planSelected={planSelected}
          />
    </div>
  );
}

Form.propTypes = {
  goBack: PropTypes.func,
  planSelected: PropTypes.object,
};

export default PaymentScreen;
