import PropTypes from 'prop-types';
import styles from './Modal.module.css';

function Modal({ handleClose, show, children }) {
  if (show) {
    return (
      <div className={styles.container}>
        <div
          className={styles.background}
          onClick={handleClose}
        ></div>
        <section className={styles.modalMain}>{children}</section>
      </div>
    );
  } else {
    return null;
  }
}

Modal.propTypes = {
  handleClose: PropTypes.func,
  children: PropTypes.object,
  show: PropTypes.bool,
};

export default Modal;
