import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  AlertMessage,
  ButtonAlpha,
  ButtonAlphaWhite,
  SecurityBar,
} from '../../components';
import { ROUTES } from '../../contants';
import { loginContants } from '../../redux/contants';
import styles from './RegistrationScreen.module.css';
import { FormTextInput } from '../../components/inputs/TextInput/index';
import { FormPasswordInput } from '../../components/inputs/PasswordInput/index';

function Header() {
  const navigate = useNavigate();

  function handleClick() {
    navigate(ROUTES.LOGIN);
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        top: 10,
        height: 50,
        flexDirection: 'row',
        position: 'absolute',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '2%',
          alignItems: 'center',
          flexDirection: 'row',
          background: '#FFFFFF',
        }}
      >
        <h6
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            color: '#414446',
            marginRight: '20px',
          }}
        >
          Ya tengo cuenta
        </h6>
        <div
          style={{
            display: 'flex',
            top: 10,
            width: 150,
            right: 10,
          }}
        >
          <ButtonAlphaWhite
            label={'Iniciar sesión'}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}

const formValidationSchema = Yup.object({
  email: Yup.string()
    .required('El email es requerido')
    .email('El email es inválido'),
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'La contraseña debe tener al menos 6 caracters')
    .max(30, 'La contraseña no puede tener mas de 30 caracters')
    .matches(
      /[0-9]/,
      'La contraseña tiene que tener al menos un numero',
    )
    .matches(
      /[A-Z]/,
      'La contraseña tiene que tener al menos una letra mayúscula',
    ),
});

const formInitialValues = {
  email: '',
  password: '',
};

function RegistrationScreen() {
  const [policies, setPolicies] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    signUpSuccessByEmail,
    errorSignUpEmailMessage,
    isLogged,
    newUser,
  } = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState(
    'Telefono o correo ya registrado',
  );

  useEffect(() => {
    if (errorSignUpEmailMessage) {
      setVisible(true);
      setMessage(errorSignUpEmailMessage);
      const timeOut = setTimeout(() => {
        setVisible(false);
        // dispatch({ type: loginContants.ON_CLEAR_ERROR_MESSAGES });
      }, 2000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [errorSignUpEmailMessage]);

  const handleLogin = async (googleData) => {
    if ('accessToken' in googleData) {
      const payload = {
        token: googleData.accessToken,
      };
      // dispatch({ type: loginContants.ON_LOGIN_BY_GOOGLE, payload });
    }
  };

  function handleClick() {
    navigate(ROUTES.POLICY);
  }

  useEffect(() => {
    if (isLogged) {
      if (newUser) {
        navigate(ROUTES.REGISTRATION_PROFILE);
      } else {
        navigate(ROUTES.HOME);
      }
    }
  }, [isLogged]);

  const handleSignUp = (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);
    const payload = {
      email: values.email.toLowerCase().trim().replace(' ', ''),
      password: values.password,
      callback: () => actions.setSubmitting(false),
    };
    // dispatch({ type: loginContants.ON_SIGNUP_BY_EMAIL, payload });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.value;
    setPolicies(value);
  };

  useEffect(() => {
    if (signUpSuccessByEmail) {
      navigate(ROUTES.REGISTRATION_VERIFICATION_CODE);
    }
  }, [signUpSuccessByEmail]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        width: '100%',
      }}
    >
      <AlertMessage visible={visible} label={message} />
      <Header />
      <div className={styles.rightContainer} style={{}}>
        <div>
          <div className={styles.cardInnerContainer}>
            <div className={styles.header}>
              <h3 className={styles.title}>
              ¡Regístrate!
              </h3>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <h2 className={styles.subtitle}>
                  Tus datos estan a salvo con nosotros.
                </h2>
              </div>
            </div>
            <Formik
              validateOnMount
              onSubmit={handleSignUp}
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
            >
              {(formik) => {
                return (
                  <Form className={styles.formContainer}>
                    <FormTextInput placeholder="Email" name="email" />
                    <FormPasswordInput
                      placeholder="Contraseña"
                      name="password"
                    />
                    <SecurityBar
                      value={
                        [
                          formik.values.password.length > 6,
                          formik.values.password.match(/[0-9]/),
                          formik.values.password.match(/[A-Z]/g),
                        ].filter((value) => Boolean(value)).length
                      }
                      maxValue={3}
                    />
                    <div className={styles.rowCenter}>
                      <input
                        type="checkbox"
                        checked={policies}
                        onChange={handleInputChange}
                      />
                      Acepto las{' '}
                      <span onClick={handleClick}>
                        políticas de privacidad{' '}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <ButtonAlpha
                        label={'Registrarme'}
                        onClick={formik.submitForm}
                        disabled={
                          !formik.isValid ||
                          formik.isSubmitting ||
                          !policies
                        }
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationScreen;
