import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ModalContentEditUserProfile.module.css';
import { ButtonAlpha } from '../../components';
import Text from '../Text';
import { FormTextInput } from '../inputs/TextInput/index';
import { FormPhoneInput } from '../inputs/PhoneInput/index';

const formValidationSchema = Yup.object({
  email: Yup.string()
    .required('El email es requerido')
    .email('El email inválido'),
  name: Yup.string().required('El nombre es requerido'),
  phone: Yup.string()
    .required('El télefono es requerido')
    .min(10, 'El télefono tiene que tener al menos 10 numeros'),
});

function ModalContentEditUserProfile({ handleCloseUpdateProfile }) {
  const dispatch = useDispatch();
  const { email, name, phone } = useSelector((state) => state.login);

  const formInitialValues = {
    name: name || '',
    phone: phone || '',
    email: email || '',
  };

  const updateProfile = (values) => {
    const userId = localStorage.getItem('userId');
    // dispatch({
    //   type: loginContants.ON_UPDATE_USER,
    //   userId,
    //   ...values,
    // });
    handleCloseUpdateProfile();
  };

  return (
    <div className={styles.container}>
      <Text
        className={styles.title}
        fontSize={20}
        fontWeight="bold"
        align="center"
      >
        Datos de cuenta
      </Text>
      <Formik
        onSubmit={updateProfile}
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.form}>
              <Text className={styles.label} fontSize={14}>
                Nombre de colaborador
              </Text>
              <FormTextInput
                name="name"
                placeholder="Nombre de colaborador"
              />
              <Text className={styles.label} fontSize={14}>
                Teléfono
              </Text>
              <FormPhoneInput name="phone" />
              <Text className={styles.label} fontSize={14}>
                Correo electrónico
              </Text>
              <FormTextInput
                name="email"
                placeholder="Correo electrónico"
              />
              <ButtonAlpha
                disabled={!formik.isValid}
                label={'Guardar cambios'}
                onClick={formik.submitForm}
                style={{
                  alignSelf: 'center',
                  marginTop: '10px',
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

ModalContentEditUserProfile.propTypes = {
  handleCloseUpdateProfile: PropTypes.func,
};

export default ModalContentEditUserProfile;
