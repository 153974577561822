/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import {  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconChevronDown } from '../../assets';
import styles from './ModalContentEditAdress.module.css';
import { ButtonAlpha, TextInput, Toggle } from '../../components';

function Row({
  day,
  isActive,
  setIsActive,
  workingHours,
  setWorkingHours,
}) {
  return (
    <div className={styles.rowContainer}>
      <div className={styles.rowColumnDays}>
        <h3 className={styles.day}>{day}</h3>
      </div>
      <div className={styles.rowColumnToggle}>
        <Toggle
          isActive={isActive}
          setIsActive={setIsActive}
          day={day}
        />
        <div className={styles.toggleText}>
          {isActive ? 'Abierto' : 'Cerrado'}
        </div>
      </div>
      <div className={styles.rowColumnSelector}>
        <select
          onChange={(e) =>
            setWorkingHours({
              ...workingHours,
              open_hour: e.target.value,
            })
          }
          value={workingHours.open_hour}
          disabled={!isActive}
          className={styles.datePickerSelector}
          name="workingOpenHours"
          id="workingOpenHours"
        >
          <option value="0:00">0:00 AM</option>
          <option value="1:00">1:00 AM</option>
          <option value="2:00">2:00 AM</option>
          <option value="3:00">3:00 AM</option>
          <option value="4:00">4:00 AM</option>
          <option value="5:00">5:00 AM</option>
          <option value="6:00">6:00 AM</option>
          <option value="7:00">7:00 AM</option>
          <option value="8:00">8:00 AM</option>
          <option value="9:00">9:00 AM</option>
          <option value="10:00">10:00 AM</option>
          <option value="11:00">11:00 AM</option>
          <option value="12:00">12:00 PM</option>
          <option value="13:00">1:00 PM</option>
          <option value="14:00">2:00 PM</option>
          <option value="15:00">3:00 PM</option>
          <option value="16:00">4:00 PM</option>
          <option value="17:00">5:00 PM</option>
          <option value="18:00">6:00 PM</option>
          <option value="19:00">7:00 PM</option>
          <option value="20:00">8:00 PM</option>
          <option value="21:00">9:00 PM</option>
          <option value="22:00">10:00 PM</option>
          <option value="23:00">11:00 PM</option>
        </select>
        <div disabled={!isActive} className={styles.faCaretDown}>
          <img draggable={false} src={IconChevronDown} />
        </div>
      </div>
      <div className={styles.rowColumnSelector}>
        <select
          value={workingHours.close_hour}
          onChange={(e) =>
            setWorkingHours({
              ...workingHours,
              close_hour: e.target.value,
            })
          }
          disabled={!isActive}
          className={styles.datePickerSelector}
          name="workingClosingHours"
          id="workingClosingHours"
        >
          <option value="0:00">0:00 AM</option>
          <option value="1:00">1:00 AM</option>
          <option value="2:00">2:00 AM</option>
          <option value="3:00">3:00 AM</option>
          <option value="4:00">4:00 AM</option>
          <option value="5:00">5:00 AM</option>
          <option value="6:00">6:00 AM</option>
          <option value="7:00">7:00 AM</option>
          <option value="8:00">8:00 AM</option>
          <option value="9:00">9:00 AM</option>
          <option value="10:00">10:00 AM</option>
          <option value="11:00">11:00 AM</option>
          <option value="12:00">12:00 PM</option>
          <option value="13:00">1:00 PM</option>
          <option value="14:00">2:00 PM</option>
          <option value="15:00">3:00 PM</option>
          <option value="16:00">4:00 PM</option>
          <option value="17:00">5:00 PM</option>
          <option value="18:00">6:00 PM</option>
          <option value="19:00">7:00 PM</option>
          <option value="20:00">8:00 PM</option>
          <option value="21:00">9:00 PM</option>
          <option value="22:00">10:00 PM</option>
          <option value="23:00">11:00 PM</option>
        </select>
        <div disabled={!isActive} className={styles.faCaretDown}>
          <img draggable={false} src={IconChevronDown} />
        </div>
      </div>
    </div>
  );
}

function RowHeader() {
  return (
    <div className={styles.rowContainer}>
      <div className={styles.rowColumnDaysTitle}>
        <div className={styles.titleRow}>Día</div>
      </div>
      <div className={styles.rowColumnToggleTitle}>
        <div className={styles.titleRow}>Abierto</div>
      </div>
      <div className={styles.rowColumnSelectorTitle}>
        <div className={styles.titleRow}>Apertura</div>
      </div>
      <div className={styles.rowColumnSelectorTitle}>
        <div className={styles.titleRow}>Cierre</div>
      </div>
    </div>
  );
}

function ModalContentEditAdress({ handleCloseUpdateAddress }) {
  const { customers } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const [newAddress, setNewAddress] = useState();

  const defaultDays = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  const [isActive, setIsActive] = useState(defaultDays);
  const [workingHours, setWorkingHours] = useState({
    monday: {
      open_hour: '0:00',
      close_hour: '0:00',
    },
    tuesday: {
      open_hour: '0:00',
      close_hour: '0:00',
    },
    wednesday: {
      open_hour: '0:00',
      close_hour: '0:00',
    },
    thursday: {
      open_hour: '0:00',
      close_hour: '0:00',
    },
    friday: {
      open_hour: '0:00',
      close_hour: '0:00',
    },
    saturday: {
      open_hour: '0:00',
      close_hour: '0:00',
    },
    sunday: {
      open_hour: '0:00',
      close_hour: '0:00',
    },
  });

  const changeDayActive = (input) => (value) => {
    setIsActive((prevValues) => ({
      ...prevValues,
      [input]: value,
    }));
  };

  const changeWorkingHour = (input) => (value) => {
    
  };

  const avoidPropagation = (event) => {
    event.stopPropagation();
  };

  const updateProfile = async (event) => {
    event.stopPropagation();
    //cambiar por customerSelected
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Dirección y Horarios</span>
      </div>

      <div className={styles.list} onClick={avoidPropagation}>
        <div className={styles.row}>
          <TextInput
            value={newAddress}
            viewLabel={true}
            setValue={setNewAddress}
            placeholder={'Dirección'}
          />
        </div>
        <div className={styles.row}>
          <RowHeader />
        </div>
      </div>
      <ButtonAlpha
        label={'Guardar cambios'}
        onClick={updateProfile}
        style={{
          width: '78%',
          height: '51px',
          alignSelf: 'center',
          marginTop: '10px',
        }}
      />
    </div>
  );
}

ModalContentEditAdress.propTypes = {
  handleCloseUpdateAddress: PropTypes.func,
};

export default ModalContentEditAdress;
