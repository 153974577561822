/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import styles from './Toggle.module.css';

function Toggle({ isActive, setIsActive }) {
  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        className={styles.input}
        checked={isActive}
        onClick={(e) => setIsActive(e.target.checked)}
      />
      <span className={`${styles.slider} ${styles.round}`}></span>
    </label>
  );
}

export default Toggle;
