import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  forgotPasswordValidateCode,
  sendForgotPasswordVerificationCode,
} from '../../redux/api';
import {
  ButtonAlpha,
  SecurityBar,
  AlertMessage,
  ButtonAlphaWhite,
} from '../../components';
import { ROUTES } from '../../contants';
import Text from '../../components/Text';
import styles from './styles.module.css';
import { PURPLE, PURPLE_A } from '../../styles/colors';
import Button from '../../components/buttons/Button';
import { loginContants } from '../../redux/contants';
import { FormPhoneInput } from '../../components/inputs/PhoneInput';
import { FormTextInput } from '../../components/inputs/TextInput/index';
import { FormPasswordInput } from '../../components/inputs/PasswordInput/index';
import { FormSplitedNumericalInput } from '../../components/inputs/SplitedNumericalInput';

function Header() {
  const navigate = useNavigate();

  function handleClick() {
    navigate(ROUTES.REGISTRATION);
  }

  return (
    <div className={styles.headerContainer}>
      <div className={styles.registrationContainer}>
        <h6 className={styles.registrationText}>
          Aún no tengo cuenta
        </h6>
        <div className={styles.buttonRegistrationContainer}>
          <ButtonAlphaWhite
            label={'Crear cuenta'}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}

const firstStepFormValidationSchema = Yup.object().shape(
  {
    email: Yup.string()
      .test(
        'one-not-empty',
        'Uno de los datos es requerido',
        function (value) {
          const phone = this.parent.phone || '';
          return !!value || !(phone.length < 3);
        },
      )
      .when('phone', {
        is: (phone) => {
          return phone?.length <= 3;
        },
        then: Yup.string().email('El email es inválido'),
      }),
    phone: Yup.string()
      .test(
        'one-not-empty',
        'Uno de los datos es requerido',
        function (value) {
          const { email } = this.parent;
          return !(value?.length < 3) || !!email;
        },
      )
      .test(
        'min-length',
        'El télefono tiene que tener al menos 10 numeros',
        function (value) {
          const { email } = this.parent;
          return !!email || value?.length >= 10;
        },
      ),
  },
  ['phone', 'email'],
);

const firstStepformInitialValues = {
  email: '',
  phone: '',
};

const FirstStep = (props) => {
  const { handleFormError, navigate, next } = props;
  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);

    try {
      const { status } = await sendForgotPasswordVerificationCode({
        key_user: values.email.toLowerCase() || '+' + values.phone,
      });
      if (status === 200) {
        next(values.email.toLowerCase() || '+' + values.phone);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message instanceof Array) {
          handleFormError(error.message.join(' '));
        } else {
          handleFormError(error.message);
        }
      } else {
        handleFormError('Algo inesperado paso');
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnMount
      onSubmit={handleFormSubmit}
      initialValues={firstStepformInitialValues}
      validationSchema={firstStepFormValidationSchema}
    >
      {(formik) => {
        return (
          <Form className={styles.formContainer}>
            <Text
              className={styles.title}
              fontSize={24}
              fontWeight="bold"
            >
              Introduce tu email o teléfono
            </Text>
            <div className={styles.firstStep__inputs}>
              <FormTextInput
                placeholder="Email"
                name="email"
                onChange={() => formik.setFieldValue('phone', '52')}
              />
              <Text fontSize={16} lineHeight={19} align="center">
                ó
              </Text>
              <FormPhoneInput
                name="phone"
                onChange={() => formik.setFieldValue('email', '')}
              />
            </div>
            <ButtonAlpha
              disabled={!formik.isValid || formik.isSubmitting}
              onClick={formik.submitForm}
              label={'Continuar'}
            />
            <Button
              onClick={() => navigate('/')}
              className={styles.firstStep__backBtn}
            >
              <Text
                fontWeight="700"
                fontSize={10}
                lineHeight={14.4}
                color={PURPLE_A}
                align="center"
              >
                ← Regresar a Inicio de Sesión
              </Text>
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

FirstStep.propTypes = {
  navigate: PropTypes.func,
  handleFormError: PropTypes.func,
  next: PropTypes.func,
};

const secondStepFormValidationSchema = Yup.object().shape({
  code: Yup.string()
    .required('El código es requerido')
    .min(6, 'El código debe tener al menos 6 digitos'),
});

const secondStepformInitialValues = {
  code: '',
};

const SecondStep = (props) => {
  const [isCodeSendLoading, setIsCodeSendLoading] = useState(false);
  const { handleFormError, keyUser, next } = props;
  const isEmail = keyUser.includes('@');

  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);
    try {
      const { status } = await forgotPasswordValidateCode({
        key_user: keyUser,
        code: values.code,
      });
      if (status === 200) {
        next();
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message instanceof Array) {
          handleFormError(error.message.join(' '));
        } else {
          handleFormError(error.message);
        }
      } else {
        handleFormError('Algo inesperado paso');
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleResendCode = async () => {
    try {
      setIsCodeSendLoading(true);
      const { status } = await sendForgotPasswordVerificationCode({
        key_user: keyUser,
      });
      if (status === 200) {
        handleFormError('El código fue reenviado con exito. ✅');
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message instanceof Array) {
          handleFormError(error.message.join(' '));
        } else {
          handleFormError(error.message);
        }
      } else {
        handleFormError('Algo inesperado paso');
      }
    } finally {
      setIsCodeSendLoading(false);
    }
  };
  return (
    <Formik
      validateOnMount
      onSubmit={handleFormSubmit}
      initialValues={secondStepformInitialValues}
      validationSchema={secondStepFormValidationSchema}
    >
      {(formik) => {
        return (
          <Form className={styles.formContainer}>
            <Text
              className={styles.title}
              fontSize={24}
              fontWeight="bold"
              align="center"
            >
              Revisa tu{' '}
              {isEmail ? ' correo electrónico' : ' teléfono'}
            </Text>
            <div className={styles.secondStep__form}>
              <Text lineHeight={24} fontSize={16} align="center">
                En breve recibirás un código en tu
                {isEmail ? ' correo ' : ' teléfono '}
                <Text
                  fontWeight="700"
                  fontSize={16}
                  lineHeight={24}
                  type="inline"
                >
                  {keyUser}
                </Text>
                , que debes introducir a continuación para restablecer
                tu contraseña.
              </Text>
              <FormSplitedNumericalInput name="code" />
              <ButtonAlpha
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={formik.submitForm}
                label={'Verificar código'}
              />
              <div className={styles.divider} />
              <Text align="center" fontSize={16}>
                ¿No recibiste el código?
              </Text>
              <ButtonAlphaWhite
                disabled={isCodeSendLoading || formik.isSubmitting}
                size="normal"
                onClick={handleResendCode}
                label={`Reenviar el código a mi  ${
                  isEmail ? ' correo ' : ' teléfono '
                }`}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

SecondStep.propTypes = {
  handleFormError: PropTypes.func,
  next: PropTypes.func,
  keyUser: PropTypes.string,
};

const thirdStepFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'La contraseña debe tener al menos 6 caracters')
    .max(30, 'La contraseña no puede tener mas de 30 caracters')
    .matches(
      /[0-9]/,
      'La contraseña tiene que tener al menos un numero',
    )
    .matches(
      /[A-Z]/,
      'La contraseña tiene que tener al menos una letra mayúscula',
    ),
  newPassword: Yup.string().test(
    'both-equals',
    'Las contraseñas no coinciden',
    function (value) {
      const { password } = this.parent;
      return password === value;
    },
  ),
});

const thirdStepformInitialValues = {
  password: '',
  newPassword: '',
};

const ThirdStep = (props) => {
  const dispatch = useDispatch();
  const { handleFormError, keyUser, navigate } = props;

  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);
    const payload = {
      key_user: keyUser,
      new_password: values.password,
      callback: (succes, error = 'Algo inesperado paso') => {
        actions.setSubmitting(false);
        if (succes) {
          navigate(ROUTES.HOME);
        } else {
          handleFormError(error);
        }
      },
    };
    // dispatch({ type: loginContants.ON_RESET_PASSWORD, payload });
  };

  return (
    <Formik
      validateOnMount
      onSubmit={handleFormSubmit}
      initialValues={thirdStepformInitialValues}
      validationSchema={thirdStepFormValidationSchema}
    >
      {(formik) => {
        return (
          <Form className={styles.formContainer}>
            <Text
              className={styles.title}
              fontSize={24}
              fontWeight="bold"
              align="center"
            >
              Restablece tu contraseña
            </Text>
            <div className={styles.thirdStep__form}>
              <FormPasswordInput
                name="password"
                placeholder="Contraseña Nueva"
              />
              <FormPasswordInput
                name="newPassword"
                placeholder="Confirmar Contraseña Nueva"
              />
              <SecurityBar
                value={
                  [
                    formik.values.password.length > 6,
                    formik.values.password.match(/[0-9]/),
                    formik.values.password.match(/[A-Z]/g),
                  ].filter((value) => Boolean(value)).length
                }
                maxValue={3}
              />

              <ButtonAlpha
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={formik.submitForm}
                label={'Restablecer contraseña'}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

ThirdStep.propTypes = {
  navigate: PropTypes.func,
  handleFormError: PropTypes.func,
  keyUser: PropTypes.string,
};

const forgotPasswordSteps = [FirstStep, SecondStep, ThirdStep];

function ForgotPasswordScreen() {
  const [step, setStep] = useState(0);
  const [formError, setFormError] = useState('');
  const [keyUser, setKeyUser] = useState('');
  const navigate = useNavigate();
  const next = (keyUser) => {
    if (keyUser) {
      setKeyUser(keyUser);
    }
    return setStep((prevStep) =>
      Math.min(forgotPasswordSteps.length - 1, prevStep + 1),
    );
  };

  const handleFormError = (error) => setFormError(error);

  const CurrentStepComponent = forgotPasswordSteps[step];

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setFormError('');
    }, 2000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [formError]);

  return (
    <div className={styles.container}>
      <AlertMessage visible={!!formError} label={formError} />
      <Header />
      <CurrentStepComponent
        next={next}
        keyUser={keyUser}
        navigate={navigate}
        handleFormError={handleFormError}
      />
    </div>
  );
}

export default ForgotPasswordScreen;
