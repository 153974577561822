import React from 'react';
import PropTypes from 'prop-types';

function VerificationCodeInput({ value, setValue }) {
  function handleOnChange(event) {
    setValue(event.target.value);
  }

  return <input value={value} onChange={handleOnChange} />;
}

VerificationCodeInput.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
};

export default VerificationCodeInput;
