import styles from './TermsAndConditions.module.css';

function TermsAndConditions() {
  return (
    <div className={styles.container}>
      <div className={styles.rightContainer}>
        <h3 className={styles.sectionTitleText}>Welcome!</h3>
        <h3 className={styles.contentSectionText}>
          We’ve drafted these Terms of Service (which we call the
          “Terms”) so you’ll know the rules that govern our
          relationship with you as a user of our Services. Although we
          have tried our best to strip the legalese from the Terms,
          there are places where they may still read like a
          traditional contract. There’s a good reason for that: these
          Terms form a legally binding contract between you and. So please read them carefully.
          <br />
          <br />
          In order to use any of our other products or services that
          are subject to these Terms (which we refer to collectively
          as the “Services”), you must accept these Terms, which are
          presented to you (i) when you first open the app and (ii)
          when we make any material changes to these Terms. Of course,
          if you don’t accept them, then don’t use the service.
        </h3>

        <h3 className={styles.sectionTitleText}>
          1. Who Can Use the Services
        </h3>
      </div>
    </div>
  );
}

export default TermsAndConditions;
