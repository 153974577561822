import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import { useEffect } from 'react';

function GoogleMap() {
  const position = {lat: 61.2176, lng: -149.8997};

  useEffect(() => {
    console.log('GoogleMap rendered', process.env);
  }, [])
  return (
    <APIProvider apiKey={''}>
      <Map
        defaultCenter={position}
        zoom={10}>
        <Marker position={position} />
      </Map>
    </APIProvider>
  );
}

export default GoogleMap;