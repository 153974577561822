/* eslint-disable react/prop-types */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { loginContants } from '../../redux/contants';
import { useDispatch, useSelector } from 'react-redux';
import {
  useStripe,
  Elements,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import styles from './CheckoutForm.module.css';
import { TextInput, AlertMessage } from '../../components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../contants';

const options = {
  style: {
    base: {
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Roboto, Source Code Pro, monospace, SFUIDisplay',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const optionsCVC = {
  placeholder: 'CVV',
  style: {
    base: {
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Roboto, Source Code Pro, monospace, SFUIDisplay',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

function Devider() {
  return (
    <div className={styles.deviderContainer}>
      <div className={styles.devider} />
    </div>
  );
}

const CheckoutForm = ({
  isFactura,
  team_id,
  setIsFactura,
  planSelected,
}) => {
  const { subcriptionSuccess, subcriptionFail } = useSelector(
    (state) => state.login,
  );
  const [isProcessing, setProcessingTo] = useState(false);
  const navigate = useNavigate();
  const [nameOfCard, setNameOfCard] = useState('');
  const [checkoutError, setCheckoutError] = useState();
  const [customerName, setCstomerName] = useState('');
  const [RFC, setRFC] = useState('');
  const [address, setAddress] = useState('');
  const [CP, setCP] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(false);
  const [isAlertMessageVisible, setIsAlertMessageVisible] =
    useState(false);

  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const handleCardDetailsChange = (event) => {
    event.error
      ? setCheckoutError(event.error.message)
      : setCheckoutError();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // our payment process starts here
    const stripeResponse = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });

    // if (stripeResponse.error) {
    //   setError(`Payment failed ${stripeResponse.error.message}`);
    //   setProcessing(false);
    // } else {
    //   setError(null);
    //   setProcessing(false);
    //   setSucceeded(true);
    // }

    const { paymentMethod } = stripeResponse;
    // dispatch({
    //   type: loginContants.ON_GET_CREDIT_CARD_METHOD_ID,
    //   methodId: paymentMethod.id,
    //   team_id: team_id,
    //   planSelected: planSelected,
    //   rfc: RFC,
    //   name: customerName,
    //   address: address,
    //   cp: CP,
    //   state: state,
    //   city: city,
    //   country: country,
    //   email: email,
    //   isFactura: isFactura,
    // });
  };

  const handleFacturaChange = (event) => {
    const target = event.target;
    const value =
      target.type === 'checkbox' ? target.checked : target.value;
    setIsFactura(value);
  };

  useEffect(() => {
    if (subcriptionFail) {
      setIsAlertMessageVisible(true);
    } else {
      setTimeout(() => {
        setIsAlertMessageVisible(false);
      }, 1000);
      // dispatch({ type: loginContants.ON_LOAD_CHECKOUT_FAIL_RESET });
    }
  }, [subcriptionFail]);

  useEffect(() => {
    if (isFactura) {
      const isDisabled =
        RFC.length === 0 ||
        customerName.length === 0 ||
        address.length === 0 ||
        CP.length === 0 ||
        state.length === 0 ||
        city.length === 0 ||
        country.length === 0 ||
        email.length === 0;

      if (isDisabled) {
        setIsCheckoutDisabled(true);
      } else {
        setIsCheckoutDisabled(false);
      }
    } else {
      setIsCheckoutDisabled(false);
    }
  }, [
    isFactura,
    customerName,
    address,
    CP,
    state,
    city,
    country,
    email,
  ]);

  useEffect(() => {
    if (subcriptionSuccess) {
      navigate(ROUTES.SUBSCRIPTION_SUCCESS);
      dispatch({ type: loginContants.ON_UPDATE_SUSCRIPTION_STATUS });
    }
  }, [subcriptionSuccess]);
  return (
    <>
      <AlertMessage
        visible={isAlertMessageVisible}
        label={'Pago declinado'}
      />
      <form onSubmit={handleSubmit} className={styles.form}>
        <TextInput
          viewLabel
          placeholder={'Nombre en tarjeta'}
          value={nameOfCard}
          setValue={setNameOfCard}
        />
        <label>
          <span>Número de tarjeta</span>
        </label>
        <fieldset className={styles.input}>
          <CardNumberElement
            options={options}
            onChange={handleCardDetailsChange}
          />
        </fieldset>
        <div className={styles.cardDetails}>
          <div className={styles.cardDetailsColumn}>
            <label>
              <span>Fecha de expiración</span>
            </label>
            <fieldset className={styles.input}>
              <CardExpiryElement
                options={options}
                onChange={handleCardDetailsChange}
              />
            </fieldset>
          </div>
          <div className={styles.cardDetailsColumn}>
            <label>
              <span>CVV</span>
            </label>
            <fieldset className={styles.input}>
              <CardCvcElement
                options={optionsCVC}
                onChange={handleCardDetailsChange}
              />
            </fieldset>
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.policyText}>
            <input
              type="checkbox"
              checked={isFactura}
              onChange={handleFacturaChange}
            />
            Quiero facturar mi compra
          </div>
        </div>

        {/* {!checkoutError && <CheckoutError>{checkoutError}</CheckoutError>} */}
        {isFactura && (
          <>
            <Devider />
            <div className={styles.inputContainer}>
              <TextInput
                viewLabel
                placeholder={'Nombre'}
                value={customerName}
                setValue={setCstomerName}
              />
            </div>
            <div className={styles.inputContainer}>
              <TextInput
                viewLabel
                placeholder={'RFC'}
                value={RFC}
                setValue={setRFC}
              />
            </div>
            <div className={styles.inputContainer}>
              <TextInput
                viewLabel
                placeholder={'Dirección'}
                value={address}
                setValue={setAddress}
              />
            </div>
            <div className={styles.formRow}>
              <div className={styles.columnInFormRow}>
                <TextInput
                  viewLabel
                  containerStyle={{
                    width: '100%',
                  }}
                  placeholder={'C.P.'}
                  value={CP}
                  setValue={setCP}
                />
              </div>
              <div className={styles.columnInFormRow}>
                <TextInput
                  containerStyle={{
                    width: '100%',
                  }}
                  viewLabel
                  placeholder={'Ciudad / Localidad'}
                  value={city}
                  setValue={setCity}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.columnInFormRow}>
                <TextInput
                  viewLabel
                  containerStyle={{
                    width: '100%',
                  }}
                  placeholder={'Estado / Provincia'}
                  value={state}
                  setValue={setState}
                />
              </div>
              <div className={styles.columnInFormRow}>
                <TextInput
                  viewLabel
                  containerStyle={{
                    width: '100%',
                  }}
                  placeholder={'Pais'}
                  value={country}
                  setValue={setCountry}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <TextInput
                viewLabel
                placeholder={'Email'}
                value={email}
                setValue={setEmail}
              />
            </div>
          </>
        )}
        <div className={styles.buttonContainer}>
          <button
            type="submit"
            disabled={isProcessing || !stripe || isCheckoutDisabled}
            className={styles.button}
          >
            Checkout
          </button>
        </div>
      </form>
    </>
  );
};

export default CheckoutForm;
