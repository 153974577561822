import styles from './ButtonAlpha.module.css';
import PropTypes from 'prop-types';

function ButtonAlpha({
  rightIcon,
  onClick,
  label,
  disabled,
  style,
  styleLabel,
  rightIconStyle,
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      style={{ ...style }}
      className={`${styles.button} ${
        rightIcon && styles.buttonRightIcon
      }`}
      onClick={onClick}
    >
      <h6 className={styles.text} style={{ ...styleLabel }}>
        {label}
      </h6>
      {rightIcon && (
        <img
          draggable={false}
          src={rightIcon}
          style={{ ...rightIconStyle }}
        />
      )}
    </button>
  );
}

ButtonAlpha.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  styleLabel: PropTypes.object,
  rightIcon: PropTypes.string,
  rightIconStyle: PropTypes.object,
};

export default ButtonAlpha;
