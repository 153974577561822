/* eslint-disable react/prop-types */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Header.module.css';
import { IconChevronDown } from '../../assets';

function Avatar() {
  const { name } = useSelector((state) => state.auth);
  return (
    <div
      className={styles.avatarDefault}
      style={{
        backgroundSize: 'cover',
      }}
    >
      <h6 className={styles.avatarDefaultText}>
        {name}
      </h6>
    </div>
  );
}

function Title({ type = 'default' }) {
  const { name, instagramUserName, customers } = useSelector(
    (state) => state.auth,
  );

  switch (type) {
    case 'settings':
      return <h1 className={styles.titleText}>Ajustes de cuenta</h1>;

    case 'profile':
      return <h1 className={styles.titleText}>Perfil</h1>;

    case 'dashboard':
      return <h1 className={styles.titleText}>Analíticas</h1>;

    case 'bussinesProfile':
      return <h1 className={styles.titleText}>Perfil Negocio</h1>;
    default:
      return (
        <h1 className={styles.titleText}>
          Hola,{' '}
          {name}
          👋
        </h1>
      );
  }
}

function Header() {
  const navigate = useNavigate();
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const { customers, isProfileCompleted } = useSelector(
    (state) => state.auth,
  );

  const titleType = (() => {
    try {
      return window.location.href.match(
        /settings|profile|bussinesProfile|bussinesSettings|dashboard/,
      )[0];
    } catch {
      return 'default';
    }
  })();


  const handleDropDownToggle = () => {
    setIsDropDownVisible((crr) => !crr);
  };

  return (
    <div className={styles.container}>
      <Title type={titleType} />
      <div className={styles.headerLeftContainer}>
        <div className={styles.profileContainer}>
          {customers ? (
            <Avatar />
          ) : (
            <div className={styles.avatarDefault} />
          )}
          <div onClick={handleDropDownToggle}>
            <img draggable={false} alt={'dropdown-toggle'} src={IconChevronDown} />
          </div>
        </div>
      </div>
    </div>
  );
}

Avatar.propTypes = {
  customers: PropTypes.array,
};

export default Header;
