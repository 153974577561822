import {createSlice} from '@reduxjs/toolkit';
import { loginContants } from '../contants';

const initialState = {
  access_token: '',
  userId: '',
  name: '',
  email: '',
  phone: '',
  role: '',
  signUpSuccess: true,
  address: '',
  isLogged: false,
  isProfileCompleted: false,
  loading: false,
  signUpSuccessByEmail: false,
  signUpSuccessByPhone: false,
  doesSignUpByPhoneFail: false,
  verificationCodeEmail: false,
  verificationCodePhone: false,
  interests: [],
  loginFail: false,
  codeVerificationFail: false,
  medals: [],
  allMedals: [],
  level: 0,
  points: 0,
  strike: 0,
  followers: 0,
  engagement: '0%',
  newUser: false,
  password: '',
  errorSignUpEmailMessage: '',
  plans: [],
  isValidateInvite: true,
  customerPlan: {},
  subcriptionSuccess: false,
  subcriptionFail: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLoginSuccess: (state, action) => {
      state.isLogged = true;
      state.email = action.payload.email;
      // state.userId = action.payload._id,
      // state.access_token = action.payload.access_token,
      state.phone = action.payload.phone;
      state.name = action.payload.name;
      state.role = action.payload.role;
      state.loading = false;
      state.loginFail = false;
      state.newUser = action.payload.newUser;
    },
    onClearLoginFail: (state, action) => {
      state.loginFail = false;
    },
    onSetPerfilCompleted: (state, action) => {
      state.isProfileCompleted = true;
    },
    onVerificationCodeByEmailFail: (state, action) => {
      state.codeVerificationFail = true;
    },
    onLoginSignUpSuccess: (state, action) => {
      state.userId = action.payload.user._id;
      state.access_token = action.payload.access_token;
      state.phone = action.payload.user.phone;
      state.email = action.payload.user.email;
      state.name = action.payload.user.name;
      state.role = action.payload.user.role;
      state.team_id = action.payload.user.team_id;
      state.loading = false;
      state.loginFail = false;
      state.newUser = action.payload.newUser;
      state.password = '';
    },
    onLoginSignUpFail: (state, action) => {
      state.loginFail = false;
    },
    onUpdateSuscriptionStatusSuccess: (state, action) => {
      state.subcriptionSuccess = action.payload.subcription_success;
    },
    onLoginFail: (state, action) => {
      state.isLogged = false;
      state.loading = false;
      state.loginFail = true;
    },
    onLogin: (state, action) => {
      state.loading = true;
      state.loginFail = false;
    },
    onSignUpSuccessByEmail: (state, action) => {
      state.userId = action.payload?._id;
      state.signUpSuccessByEmail = true;
      state.email = action.payload.email;
    },
    onSignUpSuccessByPhone: (state, action) => {
      state.userId = action.payload._id;
      state.team_id = action.payload.team_id;
      state.signUpSuccessByPhone = true;
      state.phone = '+' + action.payload.phone;
    },
    onSignUpFailByPhone: (state, action) => {
      state.doesSignUpByPhoneFail = true;
    },
    onSignUpByPhoneReset: (state, action) => {
      state.doesSignUpByPhoneFail = false;
    },
    onVerificationCodeByEmailSuccess: (state, action) => {
      state.verificationCodeEmail = true;
    },
    onVerificationCodeByPhoneSuccess: (state, action) => {
      state.verificationCodePhone = true;
    },
    onGetInterestsSuccess: (state, action) => {
      state.interests = action.interests;
    },
    onGetCategoriesSuccess: (state, action) => {
      state.categories = action.categories;
    },
    helpCenterIconActivated: (state, action) => {
      state.helpCenterIconActivated = true;
    },
    helpCenterIconDeactivated: (state, action) => {
      state.helpCenterIconActivated = false;
    },
    finishTutorial: (state, action) => {
      state.isLogged = true;
    },
    onGetUserProfileSuccess: (state, action) => {
      state.phone = action.phone;
      state.userId = action.userId;
      state.name = action.name;
      state.email = action.email;
      state.role = action.role;
    },
    onGetMedalsSuccess: (state, action) => {
      state.medals = action.medals;
    },
    onGetAllMedalsSuccess: (state, action) => {
      state.allMedals = action.allMedals;
    },
    onUpdateUserSuccess: (state, action) => {
      state.phone = action.phone;
      state.name = action.name;
      state.email = action.email;
    },
  
    onUpdateUserFail: (state, action) => {
      state.phone = action.phone;
      state.name = action.name;
      state.email = action.email;
    },
    onSignUpFailByEmail: (state, action) => {
      state.errorSignUpEmailMessage = action.errorSignUpEmailMessage;
    },
    onClearErrorMessages: (state, action) => {
      state.errorSignUpEmailMessage = '';
    },
    onLoadCheckoutSuccess: (state, action) => {
      state.payment = true;
    },
    onLoadCheckoutFail: (state, action) => {
      state.payment = false;
    },
    onLoadCheckoutFailReset: (state, action) => {
      state.payment = false;
    },
  },
});
    
export const auth = state => state;




export const {
  onLoginSuccess,
  onLoginFail,
  onLogin,
  onSignUpSuccessByEmail,
  onSignUpSuccessByPhone,
  onSignUpFailByPhone,
  onSignUpByPhoneReset,
  onVerificationCodeByEmailSuccess,
  onVerificationCodeByPhoneSuccess,
  onGetInterestsSuccess,
  helpCenterIconActivated,
  helpCenterIconDeactivated,
  finishTutorial,
  onGetUserProfileSuccess,
  onGetMedalsSuccess,
  onGetAllMedalsSuccess,
  onUpdateUserSuccess,
  onUpdateUserFail,
  onSignUpFailByEmail,
  onClearErrorMessages,
  onClearLoginFail,
  onSetPerfilCompleted,
  onLoginSignUpSuccess,
  onLoginSignUpFail,
  onUpdateSuscriptionStatusSuccess,
  onLoadCheckoutSuccess,
  onLoadCheckoutFail,
  onLoadCheckoutFailReset,
  onVerificationCodeByEmailFail,
} = authSlice.actions;

export default authSlice.reducer;
