import {configureStore} from '@reduxjs/toolkit';

// Import the API object
import { api } from './api/api'
import authReducer from './reducers/login';
import vendorReducer from './reducers/teacher';
// mount it on the Store
const store = configureStore({
  reducer: {
    // Add the generated RTK Query "API slice" caching reducer
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    vendor: vendorReducer
  },
  // Add the RTK Query API middleware
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(api.middleware),
});

export default store;

