/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './HomeScreen.module.css';
import Accounts from './Accounts';
import AccountData from './AccountData';
import PropTypes from 'prop-types';
import {
  SideMenu,
  Header,
  Modal,
  ModalContentEditAdress,
  ModalContentEditUserProfileInProfile,
  ModalContentEditBussinesProfileInProfile,
  ModalContentCompleteAccount,
} from '../../components';
import { loginContants } from '../../redux/contants';
import { ROUTES } from '../../contants';

function Content({
  onShowUpdateProfileUserModal,
  onShowUpdateProfileBussinesUserModal,
}) {
  const { name, email, phone, customers, address, role } =
    useSelector((state) => state.login);

  return (
    <div className={styles.content}>
      <div className={styles.contentInnerContainer}>
        <div className={styles.avatarContentContainer}>
          <div
            className={styles.avatarBackground}
            style={{
              backgroundSize: 'cover',
            }}
          >
            <h1 className={styles.avatarText}>
              {customers &&
                customers[0]?.name[0] + customers[0]?.name[1]}
            </h1>
          </div>
          <div className={styles.titleAvatarContainer}>
            <h1 className={styles.companyTitle}>{name}</h1>
            <h3 className={styles.subtitle}>
              {`${customers[0]?.places} campaña${
                customers[0]?.places === 1 ? '' : 's'
              } · ${customers.length} negocio${
                customers.length === 1 ? '' : 's'
              }`}
            </h3>
          </div>
        </div>
        <AccountData
          onShowUpdateProfileUserModal={onShowUpdateProfileUserModal}
          name={name}
          email={email}
          phone={phone}
        />
        <Accounts
          onShowUpdateProfileBussinesUserModal={
            onShowUpdateProfileBussinesUserModal
          }
          customers={customers}
        />
      </div>
    </div>
  );
}

function ProfileSettingsScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [
    isModalCompleteProfileVisible,
    setIsModalCompleteProfileVisible,
  ] = useState(false);
  const [
    isModalUpdateProfileVisible,
    setIsModalUpdateProfileVisible,
  ] = useState(false);
  const [
    isModalUpdateProfileBussinesVisible,
    setIsModalUpdateProfileBussinesVisible,
  ] = useState(false);

  const [isModalUpdatePlanVisible, setIsModalUpdatePlanVisible] =
    useState(false);
  const [
    isModalUpdateAddressVisible,
    setIsModalUpdateAddressVisible,
  ] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const handleStartCompleteProfile = () => {
    setIsFirstTime(false);
  };
  const handleBackCompleteProfile = () => {
    setIsFirstTime(true);
  };
  const handleCloseUpdatePlan = () => {
    setIsModalUpdatePlanVisible(false);
    navigate(ROUTES.PAYMENT);
  };
  const handleCloseCompleteProfile = () => {
    setIsModalCompleteProfileVisible(false);
  };

  const handleCloseUpdateProfile = () => {
    setIsModalUpdateProfileVisible(false);
  };

  const handleCloseUpdateAddress = () => {
    setIsModalUpdateAddressVisible(false);
  };

  const onShowUpdateProfileUserModal = (event) => {
    event.stopPropagation();
    setIsModalUpdateProfileVisible(true);
  };

  const updatePlanUser = (event) => {
    event.stopPropagation();
    // dispatch({ type: loginContants.ON_GET_PLANS });
    navigate(ROUTES.PAYMENT);
    // setIsModalUpdatePlanVisible(true);
  };

  const updateProfileAddress = (event) => {
    event.stopPropagation();
    setIsModalUpdateAddressVisible(true);
  };
  const handleCloseUpdateProfileBussines = () => {
    setIsModalUpdateProfileBussinesVisible(false);
  };

  const onShowUpdateProfileBussinesUserModal = () => {
    setIsModalUpdateProfileBussinesVisible(true);
  };
  const test = (event) => {
    event.stopPropagation();
    handleCloseCompleteProfile();
  };

  useEffect(() => {
    // dispatch({ type: loginContants.ON_GET_CUSTOMERS });
    setIsFirstTime(true);
  }, []);
  useEffect(() => {
    // dispatch({ type: loginContants.ON_GET_USER_PROFILE });
    setIsFirstTime(true);
  }, []);

  return (
    <>
      <Modal
        show={isModalCompleteProfileVisible}
        handleClose={handleCloseCompleteProfile}
      >
        <ModalContentCompleteAccount onClick={test} />
      </Modal>
      <Modal
        show={isModalUpdateProfileBussinesVisible}
        handleClose={handleCloseUpdateProfileBussines}
      >
        <ModalContentEditBussinesProfileInProfile
          handleCloseUpdateProfile={handleCloseUpdateProfileBussines}
        />
      </Modal>
      <Modal
        show={isModalUpdateProfileVisible}
        handleClose={handleCloseUpdateProfile}
      >
        <ModalContentEditUserProfileInProfile
          handleCloseUpdateProfile={handleCloseUpdateProfile}
        />
      </Modal>
      <Modal
        show={isModalUpdateAddressVisible}
        handleClose={handleCloseUpdateAddress}
      >
        <ModalContentEditAdress
          handleCloseUpdateAddress={handleCloseUpdateAddress}
        />
      </Modal>
      <div className={styles.container}>
        <SideMenu />
        <div className={styles.contentContainer}>
          <Header />
          <Content
            handleStartCompleteProfile={handleStartCompleteProfile}
            handleBackCompleteProfile={handleBackCompleteProfile}
            isFirstTime={isFirstTime}
            onShowUpdateProfileBussinesUserModal={
              onShowUpdateProfileBussinesUserModal
            }
            updateProfileAddress={updateProfileAddress}
            onShowUpdateProfileUserModal={
              onShowUpdateProfileUserModal
            }
            updatePlanUser={updatePlanUser}
          />
        </div>
      </div>
    </>
  );
}

Content.propTypes = {
  updateProfileAddress: PropTypes.func,
  isFirstTime: PropTypes.bool,
  updatePlanUser: PropTypes.func,
  handleStartCompleteProfile: PropTypes.func,
  handleBackCompleteProfile: PropTypes.func,
  onShowUpdateProfileUserModal: PropTypes.func,
};

export default ProfileSettingsScreen;
