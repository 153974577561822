import { library } from '@fortawesome/fontawesome-svg-core';

import { faInstagram } from '@fortawesome/free-brands-svg-icons';

import {
  faUpload,
  faSearch,
  faTimes,
  faAngleLeft,
  faAngleRight,
  faStepBackward,
  faStepForward,
  faArrowDown,
  faUser,
  faUsers,
  faUserFriends,
  faEdit,
  faArrowRight,
  faCheck,
  faMobileAlt,
} from '@fortawesome/fontawesome-free-solid';

import {
  faFile,
  faEnvelope,
  faTimesCircle,
  faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faInstagram,
  faUpload,
  faTimesCircle,
  faPenToSquare,
  faSearch,
  faTimes,
  faAngleLeft,
  faAngleRight,
  faStepBackward,
  faStepForward,
  faArrowDown,
  faUser,
  faUsers,
  faUserFriends,
  faEdit,
  faFile,
  faArrowRight,
  faCheck,
  faMobileAlt,
  faEnvelope,
);
