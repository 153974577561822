import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './SubcriptionCustomerSuccess.module.css';
import {
  Card,
  ButtonAlphaWhite,
} from '../../components';
import { ROUTES } from '../../contants';

function Header() {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        top: 10,
        height: 50,
        flexDirection: 'row',
        position: 'absolute',
      }}
    >
      <div
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '2%',
          alignItems: 'center',
        }}
      ></div>
    </div>
  );
}

function SubcriptionCustomerSuccess() {
  // const dispatch = useDispatch();
  const { name } = useSelector(
    (state) => state.login,
  );
  
  const navigate = useNavigate();
  const handleGoToHome = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div className={styles.container} >
      <Header />
      <div className={styles.cardContainer}>
        <Card size="large">
          <div className={styles.cardInnerContainer}>
            <div className={styles.row}>
              <div className={styles.title}>¡Felicidades!</div>
              <span>
                Ahora eres maestro {name}
              </span>
            </div>
            <div className={styles.row}>
            </div>
            <div className={styles.row}>
              <ButtonAlphaWhite
                label={'Ir al inicio'}
                onClick={handleGoToHome}
                size={'medium'}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default SubcriptionCustomerSuccess;
