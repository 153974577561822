import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  AlertMessage,
  ButtonAlpha,
  ButtonAlphaWhite,
  GoogleMap
} from '../../components';
import { ROUTES } from '../../contants';
import Text from '../../components/Text';
import styles from './LoginScreen.module.css';
import { PURPLE_A } from '../../styles/colors';
import Button from '../../components/buttons/Button';
import { FormTextInput } from '../../components/inputs/TextInput/index';
import { FormPasswordInput } from '../../components/inputs/PasswordInput/index';
import i18next from '../../translations';

function Header() {
  const navigate = useNavigate();
  const {t} = useTranslation('common');
  const [language, setLanguage] = useState('es');
  function handleClick() {
    navigate(ROUTES.REGISTRATION);
  }

  function handleRegisterTeacherClick() {
    navigate(ROUTES.TEACHER_REGISTRATION);
  }

  function handleChangeLanguage() {
    if (language === 'es') {
      i18next.changeLanguage('en');
      setLanguage('en');
    } else {
      i18next.changeLanguage('es');
      setLanguage('es');
    }
  }

  return (
    <div className={styles.headerContainer}>
      <div className={styles.registrationContainer}>
      <div className={styles.buttonRegistrationContainer}>
          <ButtonAlphaWhite
            label={language}
            onClick={handleChangeLanguage}
          />
        </div>
      <div className={styles.buttonRegistrationContainer}>
          <ButtonAlphaWhite
            label={t('login.title')}
            onClick={handleRegisterTeacherClick}
          />
        </div>
        <div className={styles.buttonRegistrationContainer}>
          <ButtonAlphaWhite
            label={'Crear cuenta'}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}

const formValidationSchema = Yup.object({
  email: Yup.string()
    .required('El Phone es requerido'),
  password: Yup.string().required('La contraseña es requerida'),
});

const formInitialValues = {
  email: '',
  password: '',
};

function LoginScreen() {
  const dispatch = useDispatch();
  // const { loginFail, isLogged, newUser } = useSelector(
  //   (state) => state.login,
  // );
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const handleLogin = async (googleData) => {
    if ('accessToken' in googleData) {
      const payload = {
        token: googleData.accessToken,
      };
      // dispatch({ type: loginContants.ON_LOGIN_BY_GOOGLE, payload });
    }
  };

  const handleSignIn = (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);
    const payload = {
      username: values.email.toLowerCase().trim().replace(' ', ''),
      password: values.password,
      callback: () => actions.setSubmitting(false),
    };
    // dispatch({ type: loginContants.ON_LOGIN, payload });
  };

  // useEffect(() => {
  //   if (isLogged) {
  //     if (newUser) {
  //       navigate(ROUTES.REGISTRATION_PROFILE);
  //     } else {
  //       navigate(ROUTES.HOME);
  //     }
  //   }
  // }, [isLogged]);

  // useEffect(() => {
  //   if (loginFail) {
  //     setVisible(true);
  //     const timeOut = setTimeout(() => {
  //       setVisible(false);
  //       // dispatch({ type: loginContants.ON_CLEAR_LOGIN_FAIL });
  //     }, 2000);

  //     return () => {
  //       clearTimeout(timeOut);
  //     };
  //   }
  // }, [loginFail]);

  const { t } = useTranslation('common');

  useEffect(() => {
    console.log('something runnning', process.env.GOOGLE_CLIENT_ID)
  }, [])

  return (
    <div className={styles.container} style={{}}>
      <AlertMessage
        visible={visible}
        label={'El correo o la contraseña no coinciden 🔐'}
      />
      <Header />
      <Formik
        validateOnMount
        onSubmit={handleSignIn}
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.formContainer}>
              <Text
                className={styles.title}
                fontSize={24}
                fontWeight="bold"
              >
                {t('login.title')}
              </Text>
              <FormTextInput placeholder="Email" name="email" />
              <FormPasswordInput
                placeholder="Contraseña"
                name="password"
              />
              <div className={styles.loginButtonsContainer}>
                <ButtonAlpha
                  label={'Iniciar sesión'}
                  onClick={formik.submitForm}
                  disabled={!formik.isValid || formik.isSubmitting}
                />
              </div>
              <Button
                className={styles.forgotPasswordBtn}
                onClick={() => navigate(ROUTES.FORGOT_PASSWORD)}
              >
                <Text
                  fontWeight="700"
                  fontSize={10}
                  lineHeight={14.4}
                  color={PURPLE_A}
                  align="center"
                >
                  ¿Olvidaste tu contraseña?
                </Text>
              </Button>
            </Form>
          );
        }}
      </Formik>
      <GoogleMap />
    </div>
  );
}

export default withTranslation()(LoginScreen);
