/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
  GRAY,
  GRAY_LIGHT,
  PURPLE_A,
  PURPLE_INACTIVE,
  RED,
  WHITE,
} from '../../../styles/colors';
import Text from '../../../components/Text';
import { defaultFont } from '../../../styles/fonts';
import { forwardInputEventHandlers } from '../../../utils/form.utils';

const TextInput = forwardRef((props, ref) => {
  const { error, className, inputClassName, ...restProps } = props;

  const styles = css`
    z-index: 0;
    position: relative;
    margin-bottom: 25px;
    box-sizing: border-box;
    width: 100%;

    & > * {
      box-sizing: border-box;
    }

    .error-text {
      z-index: 1;
      bottom: -19px;
      position: absolute;
    }

    input {
      z-index: 2;
      height: 60px;
      width: 100%;
      color: ${GRAY};
      border-radius: 16px;
      padding: 0 19px;
      border: 1px solid ${error ? RED : PURPLE_INACTIVE};
      font-family: ${defaultFont};
      font-size: 16px;
      outline: none;

      &:-webkit-autofill {
        -webkit-text-fill-color: ${GRAY};
        background-color: ${WHITE} !important;
        box-shadow: inset 0 0 0 1px rgb(255 255 255 / 0%),
          inset 0 0 0 1000px ${WHITE};
      }

      &:focus {
        border-color: ${error ? RED : PURPLE_A};
      }

      &::placeholder {
        color: ${GRAY_LIGHT};
      }
    }
  `;

  return (
    <div ref={ref} css={styles} className={className}>
      {!!error && (
        <Text
          fontSize={14}
          lineHeight={17}
          fontWeight="500"
          color={RED}
          className="error-text"
        >
          {error}
        </Text>
      )}
      <input type="text" className={inputClassName} {...restProps} />
    </div>
  );
});

export const FormTextInput = (props) => {
  const { name, error, ...restProps } = props;

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <TextInput
          name={name}
          value={meta.value}
          error={meta.touched && (meta.error || error)}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

FormTextInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

TextInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};

TextInput.displayName = 'Button';

export default TextInput;
