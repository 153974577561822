/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import styles from './Accounts.module.css';
import moment from 'moment';
import { ButtonAlphaWhite } from '../../components';

function AccountDataComponent({
  title,
  image,
  createAt,
  onShowUpdateProfileBussinesUserModal,
}) {
  return (
    <div className={styles.accountDataContainer}>
      <div className={styles.accountDataColumnImage}>
        <img src={image} className={styles.logo} />
      </div>
      <div className={styles.accountDataColumnName}>{title}</div>
      <div className={styles.accountDataColumnDate}>
        Desde {moment(createAt).format('DD MMM YYYY')}
      </div>
      <div className={styles.accountDataColumnButton}>
        <div className={styles.accountDataColumnInnerButton}>
          <ButtonAlphaWhite
            onClick={onShowUpdateProfileBussinesUserModal}
            label={'Editar negocio'}
            size={'small'}
          />
        </div>
      </div>
    </div>
  );
}

function Accounts({
  customers,
  onShowUpdateProfileBussinesUserModal,
}) {
  return (
    <div className={styles.contentContainer}>
      <div className={styles.container}>
        <div className={styles.headerTitle}>
          <div>
            <h1 className={styles.headerTitleText}>Tus negocios</h1>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.row}>
            {customers.map((customer) => {
              return (
                <>
                  <AccountDataComponent
                    createAt={customer.createAt}
                    title={customer.name}
                    image={customer.image}
                    onShowUpdateProfileBussinesUserModal={
                      onShowUpdateProfileBussinesUserModal
                    }
                  />
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accounts;
