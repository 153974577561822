// import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { loginContants } from '../../redux/contants';
import styles from './RegistrationCustomerSuccess.module.css';
import { Card, ButtonAlpha } from '../../components';
import { ROUTES } from '../../contants';
import ProgressBar from './ProgressBar';

function Header() {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        top: 10,
        height: 50,
        flexDirection: 'row',
        position: 'absolute',
      }}
    >
      <div
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '2%',
          alignItems: 'center',
        }}
      ></div>
    </div>
  );
}

function RegistrationCustomerSuccess() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGoToHome = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div
      className={styles.container}
    >
      <Header />
      <div className={styles.cardContainer}>
        <Card size="large">
          <div className={styles.cardInnerContainer}>
            <div className={styles.row}>
              <div className={styles.title}>¡Felicidades!</div>
              <span>
                Ahora eres socio , puede comenzar a
                configurar tus primeras clases
              </span>
            </div>
            <div className={styles.row}>
              <ButtonAlpha
                label={'Comenzar'}
                onClick={handleGoToHome}
              />
            </div>
            <div className={styles.row}>
              <div className={styles.ProgressBar}>
                <div className={styles.label}>Paso 3 de 3 </div>
                <ProgressBar
                  bgcolor="#5652E5"
                  completed="100"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default RegistrationCustomerSuccess;
