import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SideMenu.module.css';
import { IconChevronDown } from '../../assets';

function SideMenu() {
  const dispatch = useDispatch();
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const { name, email, phone, address, role } =
    useSelector((state) => state.login);

  useEffect(() => {
    // dispatch({ type: loginContants.ON_GET_USER_PROFILE });
  }, []);


  const handleDropDownToggle = () => {
    setIsDropDownVisible((crr) => !crr);
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerCompanies}>
        <div className={styles.barContainer}>
        </div>
      </div>
      <div className={styles.containerMenu}>
        <div className={styles.menuTitleContainer}>
          <div
            className={styles.iconMenuContainer}
            onClick={handleDropDownToggle}
          >
            <img src={IconChevronDown} />
          </div>
          
        </div>
        <div className={styles.divider} />
        <div className={styles.sectionMenuContainer}>

        </div>
      </div>
    </div>
  );
}

export default SideMenu;
